<template>
  <div class="w-100">
    <!-- <br> -->
    <a-spin :spinning="spinning">
      <base-alert v-if="errors.length > 0 && showErrors == true" type="danger">
        <b>You have the following error(s):</b>
        <li v-for="error in errors" :key="error">{{ error }}</li>
      </base-alert>

      <div class="row card-body">
        <div class="col-md-12" v-if="title != null">
          <h1>{{ title }}</h1>
        </div>
        <div class="col-md-12" v-if="description != null">
          <h4>{{ description }}</h4>
          <br />
        </div>
        <div v-if="mode == 'tabs'" class="col-md-12">
          <a-tabs default-active-key="1" type="card">
            <a-tab-pane
                v-if="!getConditionResult(groups).includes(false)"
                v-for="groups in settingsArray"
                :tab="groups['name']"
                :key="groups['sequence']"
                :class="groups.class"
                :style="groups.style"
            >
              <!-- {{settingsGroupHash}} -->
              <div
                  v-if="
                  (groups['terminalSettings'] == null) == true &&
                  !getConditionResult(groups).includes(false)
                "
                  class="row"
              >
                <div v-if="groups['mode'] == 'list'">
                  <div
                      v-for="subGroup in groups['subGroups']"
                      :class="subGroup.class"
                      :key="subGroup['sequence']"
                  >
                    <!-- <div v-if="getConditionResult(subGroup) != null"> -->
                    <div class="col-md-12">
                      <h2>{{ subGroup.name }}</h2>
                      <p v-if="subGroup.description">
                        {{ subGroup.description }}
                      </p>
                    </div>

                    <div
                        v-for="subSetting in subGroup['terminalSettings']"
                        :class="subSetting.class"
                        :style="subSetting.style"
                        :key="subSetting['code']"
                    >
                      <div v-if="isVisible(subSetting) == true">
                        <!-- <div v-if="isVisible(subSetting) == true" > -->
                        <div
                            v-if="subSetting['type'] == 'text'"
                            :style="subSetting.style"
                        >
                          <h3 v-if="subSetting['header']">
                            <br />
                            {{ subSetting['header'] }}
                          </h3>
                          <base-input
                              :description="subSetting['description']"
                              :label="subSetting['label']"
                              placeholder=""
                              input-classes="form-control"
                              v-model="settingsModel[subSetting['code']]"
                              :required="subSetting['required']"
                              :disabled="checkDisabled(subSetting)"
                          />
                        </div>

                        <div
                            v-if="subSetting['type'] == 'label'"
                            :style="subSetting.style"
                        >
                          <h3 v-if="subSetting['header']">
                            <br />
                            {{ subSetting['header'] }}
                          </h3>
                          <h5>{{ subSetting['label'] }}</h5>
                          <!-- <base-input
                            :description="" :label="subSetting['label']" placeholder="" input-classes="form-control" v-model="settingsModel[subSetting['code']]" /> -->
                        </div>

                        <div
                            v-if="setting['type'] == 'number'"
                            :style="setting.style"
                        >
                          <h3 v-if="setting['header']">
                            <br />
                            {{ setting['header'] }}
                          </h3>
                          <base-input
                              :required="setting['required']"
                              v-if="
                              setting['type'] == 'number' &&
                              setting['formula'] == undefined
                            "
                              :label="setting['label']"
                              placeholder=""
                              input-classes="form-control"
                              type="number"
                              v-model="settingsModel[setting['code']]"
                              :description="setting['description']"
                              @change="checkEmptyNumber(setting['code'])"
                              :disabled="
                              setting['disableEdit'] != undefined
                                ? setting['disableEdit']
                                : false || checkDisabled(setting)
                            "
                          />
                          <base-input
                              :required="setting['required']"
                              v-else
                              :label="setting['label']"
                              placeholder=""
                              input-classes="form-control"
                              type="number"
                              :value="
                              computeFormula(
                                setting['formula'],
                                setting['code'],
                                setting['valueCondition'] == undefined
                                  ? null
                                  : setting['valueCondition'],
                                setting['label']
                              )
                            "
                              v-model="settingsModel[setting['code']]"
                              :description="setting['description']"
                              :disabled="true"
                          />
                        </div>

                        <base-input
                            :description="subSetting['description']"
                            v-if="subSetting['type'] == 'select'"
                            :label="subSetting['label']"
                            :style="subSetting.style"
                            :required="subSetting['required']"
                        >
                          <h3 v-if="subSetting['header']">
                            <br />
                            {{ subSetting['header'] }}
                          </h3>
                          <a-select
                              style="width: 100%"
                              placeholder="Please select"
                              optionFilterProp="children"
                              v-model="settingsModel[subSetting['code']]"
                              :disabled="checkDisabled(subSetting)"
                          >
                            <a-select-option
                                v-for="option in subSetting['options']"
                                :key="option.id"
                                :value="option.id"
                            >{{ option.name }}</a-select-option
                            >
                          </a-select>
                        </base-input>

                        <div
                            v-if="subSetting['type'] == 'boolean'"
                            :style="subSetting.style"
                        >
                          <h3 v-if="subSetting['header']">
                            <br />
                            {{ subSetting['header'] }}
                          </h3>
                          <base-checkbox
                              class="mb-3"
                              v-model="settingsModel[subSetting['code']]"
                              :checked="settingsModel[subSetting['code']]"
                              :modelName="subSetting['code']"
                              :disabled="checkDisabled(subSetting)"
                          >
                            {{ subSetting['label'] }}
                          </base-checkbox>
                        </div>

                        <base-input
                            :description="subSetting['description']"
                            v-if="subSetting['type'] == 'multiple_select'"
                            :label="subSetting['label']"
                            :style="subSetting.style"
                            :required="subSetting['required']"
                        >
                          <h3 v-if="subSetting['header']">
                            <br />
                            {{ subSetting['header'] }}
                          </h3>
                          <a-select
                              mode="multiple"
                              style="width: 100%"
                              placeholder="Please select"
                              optionFilterProp="children"
                              v-model="settingsModel[subSetting['code']]"
                              :disabled="checkDisabled(subSetting)"
                          >
                            <a-select-option
                                v-for="option in subSetting['options']"
                                :key="option.id"
                                :value="option.id"
                            >{{ option.name }}</a-select-option
                            >
                          </a-select>
                        </base-input>

                        <div v-if="subSetting['type'] == 'searchable_select'">
                          <base-input
                              :description="subSetting['description']"
                              :label="subSetting['label']"
                              :required="subSetting['required']"
                          >
                            <a-select
                                show-search
                                style="width: 100%"
                                placeholder="Please select"
                                optionFilterProp="children"
                                v-model="settingsModel[subSetting['code']]"
                                :disabled="checkDisabled(subSetting)"
                            >
                              <a-select-option
                                  v-for="option in subSetting['options']"
                                  :key="option.id"
                                  :value="option.id"
                              >{{ option.name }}</a-select-option
                              >
                            </a-select>
                          </base-input>
                        </div>

                        <base-input
                            :description="subSetting['description']"
                            v-if="subSetting['type'] == 'date'"
                            :label="subSetting['label']"
                            :style="subSetting.style"
                            :required="subSetting['required']"
                        >
                          <h3 v-if="subSetting['header']">
                            <br />
                            {{ subSetting['header'] }}
                          </h3>
                          <br /><a-date-picker
                            @change="
                              (date, dateString) =>
                                handleDatePickerChange(
                                  date,
                                  dateString,
                                  subSetting['code']
                                )
                            "
                            v-model="settingsModel[subSetting['code']]"
                            :format="dateFormat"
                            input-classes="form-control"
                            :disabled="checkDisabled(subSetting)"
                        />
                        </base-input>

                        <base-input
                            :description="subSetting['description']"
                            v-if="subSetting['type'] == 'time'"
                            :label="subSetting['label']"
                            :style="subSetting.style"
                            :required="subSetting['required']"
                        >
                          <h3 v-if="subSetting['header']">
                            <br />
                            {{ subSetting['header'] }}
                          </h3>
                          <br /><a-time-picker
                            valueFormat="H:mm:ss"
                            format="H:mm:ss"
                            v-model="settingsModel[subSetting['code']]"
                            input-classes="form-control"
                        />
                        </base-input>

                        <base-input
                            :description="subSetting['description']"
                            v-if="subSetting['type'] == 'radio'"
                            :label="subSetting['label']"
                            :style="subSetting.style"
                            :required="subSetting['required']"
                            :disabled="checkDisabled(subSetting)"
                        >
                          <br />
                          <h3 v-if="subSetting['header']">
                            <br />
                            {{ subSetting['header'] }}
                          </h3>
                          <a-radio-group
                              v-model="settingsModel[subSetting['code']]"
                          >
                            <a-radio
                                v-if="option.description == null"
                                v-for="option in subSetting['options']"
                                :style="radioStyle"
                                :value="option.id"
                                :key="option.id"
                            >
                              {{ option.name }}
                            </a-radio>
                            <a-radio
                                v-if="option.description != null"
                                v-for="option in subSetting['options']"
                                :style="radioStyle2"
                                :value="option.id"
                                :key="option.id"
                            >
                              <b>{{ option.name }}</b>
                              <p style="padding-left: 25px">
                                <small>{{ option.description }}</small>
                              </p>
                            </a-radio>
                          </a-radio-group>
                        </base-input>

                        <base-input
                            :description="subSetting['description']"
                            v-if="subSetting['type'] == 'slider'"
                            :label="subSetting['label']"
                            :style="subSetting.style"
                            :required="subSetting['required']"
                            :disabled="checkDisabled(subSetting)"
                        >
                          <h3 v-if="subSetting['header']">
                            <br />
                            {{ subSetting['header'] }}
                          </h3>
                          <div v-if="subSetting.legend">
                            <br /><span
                              class="badge"
                              style="background-color: #9d3ffa"
                          >&nbsp;&nbsp;&nbsp;&nbsp;</span
                          >
                            {{ subSetting.legend }}
                          </div>
                          <div v-if="errorMessage[subSetting.code] != null">
                            <br />
                            <base-alert type="warning" style="height: 50px">
                              {{ errorMessage[subSetting.code] }}
                            </base-alert>
                            <br />
                          </div>
                          <a-slider
                              :defaultValue="[]"
                              @change="checkSliderValue(subSetting)"
                              v-model="settingsModel[subSetting['code']]"
                              :marks="
                              generateMarks(
                                settingsModel[subSetting.maximumValue]
                              )
                            "
                              :step="1"
                              :max="
                              parseInt(settingsModel[subSetting.maximumValue])
                            "
                              :min="1"
                              range
                          />
                        </base-input>

                        <base-input
                            :description="subSetting['description']"
                            v-if="subSetting['type'] == 'switch'"
                            class="row"
                            :style="subSetting.style"
                            :disabled="checkDisabled(subSetting)"
                        >
                          <h3 v-if="subSetting['header']">
                            <br />
                            {{ subSetting['header'] }}
                          </h3>
                          <div class="col-md-10">
                            <h3>{{ subSetting['label'] }}</h3>
                            <small>{{ subSetting['description'] }}</small>
                          </div>
                          <div class="float-right col-md-2">
                            <a-switch
                                v-model="settingsModel[subSetting['code']]"
                            />
                          </div>
                        </base-input>
                      </div>
                    </div>
                    <!-- </div> -->
                    <br />
                  </div>
                </div>
                <div v-else>
                  <a-tabs tabPosition="left" default-active-key="1">
                    <a-tab-pane
                        v-for="subGroup in groups['subGroups']"
                        :tab="subGroup['name']"
                        :key="subGroup['sequence']"
                        class="row"
                    >
                      <div
                          v-for="subSetting in subGroup['terminalSettings']"
                          :class="subSetting.class"
                          :style="subSetting.style"
                          :key="subSetting['code']"
                      >
                        <div v-if="isVisible(subSetting) == true">
                          <!-- <base-input
                            :description="" v-if="subSetting['type'] == 'text'" :label="subSetting['label']" placeholder="" input-classes="form-control" v-model="settingsModel[subSetting['code']]" /> -->
                          <div
                              v-if="subSetting['type'] == 'text'"
                              :style="subSetting.style"
                          >
                            <h3 v-if="subSetting['header']">
                              <br />
                              {{ subSetting['header'] }}
                            </h3>
                            <base-input
                                :description="subSetting['description']"
                                :required="subSetting['required']"
                                :label="subSetting['label']"
                                placeholder=""
                                input-classes="form-control"
                                v-model="settingsModel[subSetting['code']]"
                                :disabled="checkDisabled(subSetting)"
                            />
                          </div>

                          <div
                              v-if="subSetting['type'] == 'label'"
                              :style="subSetting.style"
                          >
                            <h3 v-if="subSetting['header']">
                              <br />
                              {{ subSetting['header'] }}
                            </h3>
                            <h5>{{ subSetting['label'] }}</h5>
                            <!-- <base-input
                              :description="" :label="subSetting['label']" placeholder="" input-classes="form-control" v-model="settingsModel[subSetting['code']]" /> -->
                          </div>

                          <div
                              v-if="subSetting['type'] == 'number'"
                              :style="subSetting.style"
                          >
                            <h3 v-if="subSetting['header']">
                              <br />
                              {{ subSetting['header'] }}
                            </h3>
                            <base-input
                                :description="subSetting['description']"
                                :required="subSetting['required']"
                                v-if="subSetting['type'] == 'number'"
                                :label="subSetting['label']"
                                placeholder=""
                                input-classes="form-control"
                                type="number"
                                v-model="settingsModel[subSetting['code']]"
                                :disabled="checkDisabled(subSetting)"
                            />
                          </div>

                          <base-input
                              :description="subSetting['description']"
                              :required="subSetting['required']"
                              v-if="subSetting['type'] == 'select'"
                              :label="subSetting['label']"
                              :style="subSetting.style"
                          >
                            <h3 v-if="subSetting['header']">
                              <br />
                              {{ subSetting['header'] }}
                            </h3>
                            <a-select
                                style="width: 100%"
                                placeholder="Please select"
                                optionFilterProp="children"
                                v-model="settingsModel[subSetting['code']]"
                                :disabled="checkDisabled(subSetting)"
                            >
                              <a-select-option
                                  v-for="option in subSetting['options']"
                                  :key="option.id"
                                  :value="option.id"
                              >{{ option.name }}</a-select-option
                              >
                            </a-select>
                          </base-input>

                          <div
                              v-if="subSetting['type'] == 'boolean'"
                              :style="subSetting.style"
                          >
                            <h3 v-if="subSetting['header']">
                              <br />
                              {{ subSetting['header'] }}
                            </h3>
                            <base-checkbox
                                class="mb-3"
                                v-model="settingsModel[subSetting['code']]"
                                :checked="settingsModel[subSetting['code']]"
                                :modelName="subSetting['code']"
                                :disabled="checkDisabled(subSetting)"
                            >
                              {{ subSetting['label'] }}
                            </base-checkbox>
                          </div>

                          <base-input
                              :description="subSetting['description']"
                              :required="subSetting['required']"
                              v-if="subSetting['type'] == 'multiple_select'"
                              :label="subSetting['label']"
                              :style="subSetting.style"
                              :disabled="checkDisabled(subSetting)"
                          >
                            <h3 v-if="subSetting['header']">
                              <br />
                              {{ subSetting['header'] }}
                            </h3>
                            <a-select
                                mode="multiple"
                                style="width: 100%"
                                placeholder="Please select"
                                optionFilterProp="children"
                                v-model="settingsModel[subSetting['code']]"
                            >
                              <a-select-option
                                  v-for="option in subSetting['options']"
                                  :key="option.id"
                                  :value="option.id"
                              >{{ option.name }}</a-select-option
                              >
                            </a-select>
                          </base-input>

                          <base-input
                              :description="subSetting['description']"
                              :required="subSetting['required']"
                              v-if="subSetting['type'] == 'date'"
                              :label="subSetting['label']"
                              :style="subSetting.style"
                              :disabled="checkDisabled(subSetting)"
                          >
                            <h3 v-if="subSetting['header']">
                              <br />
                              {{ subSetting['header'] }}
                            </h3>
                            <br /><a-date-picker
                              @change="
                                (date, dateString) =>
                                  handleDatePickerChange(
                                    date,
                                    dateString,
                                    subSetting['code']
                                  )
                              "
                              v-model="settingsModel[subSetting['code']]"
                              :format="dateFormat"
                              input-classes="form-control"
                          />
                          </base-input>

                          <base-input
                              :description="subSetting['description']"
                              :required="subSetting['required']"
                              v-if="subSetting['type'] == 'time'"
                              :label="subSetting['label']"
                              :style="subSetting.style"
                              :disabled="checkDisabled(subSetting)"
                          >
                            <h3 v-if="subSetting['header']">
                              <br />
                              {{ subSetting['header'] }}
                            </h3>
                            <br /><a-time-picker
                              valueFormat="H:mm:ss"
                              format="H:mm:ss"
                              v-model="settingsModel[subSetting['code']]"
                              input-classes="form-control"
                          />
                          </base-input>

                          <base-input
                              :description="subSetting['description']"
                              :required="subSetting['required']"
                              v-if="subSetting['type'] == 'radio'"
                              :label="subSetting['label']"
                              :style="subSetting.style"
                              :disabled="checkDisabled(subSetting)"
                          >
                            <br />
                            <h3 v-if="subSetting['header']">
                              <br />
                              {{ subSetting['header'] }}
                            </h3>
                            <a-radio-group
                                v-model="settingsModel[subSetting['code']]"
                            >
                              <a-radio
                                  v-if="option.description == null"
                                  v-for="option in subSetting['options']"
                                  :style="radioStyle"
                                  :value="option.id"
                                  :key="option.id"
                              >
                                {{ option.name }}
                              </a-radio>
                              <a-radio
                                  v-if="option.description != null"
                                  v-for="option in subSetting['options']"
                                  :style="radioStyle2"
                                  :value="option.id"
                                  :key="option.id"
                              >
                                <b>{{ option.name }}</b>
                                <p style="padding-left: 25px">
                                  <small>{{ option.description }}</small>
                                </p>
                              </a-radio>
                            </a-radio-group>
                          </base-input>

                          <base-input
                              :description="subSetting['description']"
                              :required="subSetting['required']"
                              v-if="subSetting['type'] == 'slider'"
                              :label="subSetting['label']"
                              :style="subSetting.style"
                              :disabled="checkDisabled(subSetting)"
                          >
                            <h3 v-if="subSetting['header']">
                              <br />
                              {{ subSetting['header'] }}
                            </h3>
                            <div v-if="subSetting.legend">
                              <br /><span
                                class="badge"
                                style="background-color: #9d3ffa"
                            >&nbsp;&nbsp;&nbsp;&nbsp;</span
                            >
                              {{ subSetting.legend }}
                            </div>
                            <div v-if="errorMessage[subSetting.code] != null">
                              <br />
                              <base-alert type="warning" style="height: 50px">
                                {{ errorMessage[subSetting.code] }}
                              </base-alert>
                              <br />
                            </div>
                            <a-slider
                                :defaultValue="[]"
                                @change="checkSliderValue(subSetting)"
                                v-model="settingsModel[subSetting['code']]"
                                :marks="
                                generateMarks(
                                  settingsModel[subSetting.maximumValue]
                                )
                              "
                                :step="1"
                                :max="
                                parseInt(settingsModel[subSetting.maximumValue])
                              "
                                :min="1"
                                range
                            />
                          </base-input>

                          <base-input
                              :description="subSetting['description']"
                              v-if="subSetting['type'] == 'switch'"
                              class="row"
                              :style="subSetting.style"
                              :disabled="checkDisabled(subSetting)"
                          >
                            <h3 v-if="subSetting['header']">
                              <br />
                              {{ subSetting['header'] }}
                            </h3>
                            <div class="col-md-10">
                              <h3>{{ subSetting['label'] }}</h3>
                              <small>{{ subSetting['description'] }}</small>
                            </div>
                            <div class="float-right col-md-2">
                              <a-switch
                                  v-model="settingsModel[subSetting['code']]"
                                  :disabled="checkDisabled(subSetting)"
                              />
                            </div>
                          </base-input>
                        </div>
                      </div>
                    </a-tab-pane>
                  </a-tabs>
                </div>
              </div>
              <div
                  v-if="
                  (groups['terminalSettings'] != null) == true &&
                  !getConditionResult(groups).includes(false)
                "
                  class="row"
              >
                <div
                    v-for="setting in groups['terminalSettings']"
                    :class="setting.class"
                    :style="setting.style"
                    :key="setting['code']"
                >
                  <div v-if="isVisible(setting) == true">
                    <div
                        v-if="setting['type'] == 'text'"
                        :style="setting.style"
                    >
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>

                      <base-input
                          :description="setting['description']"
                          :required="setting['required']"
                          :label="setting['label']"
                          placeholder=""
                          input-classes="form-control"
                          v-model="settingsModel[setting['code']]"
                          :disabled="checkDisabled(setting)"
                      />
                    </div>

                    <div
                        v-if="setting['type'] == 'label'"
                        :style="setting.style"
                    >
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>

                      <h5>{{ setting['label'] }}</h5>
                      <!-- <base-input
                        :description="" :label="subSetting['label']" placeholder="" input-classes="form-control" v-model="settingsModel[subSetting['code']]" /> -->
                    </div>

                    <div v-if="setting['type'] == 'searchable_select'">
                      <searchable-select
                          :subSetting="setting"
                          :disabled="checkDisabled(setting, true)"
                          :key="externalSelect"
                          @updateValues="updateHashValue"
                      ></searchable-select>
                    </div>

                    <div
                        v-if="setting['type'] == 'number'"
                        :style="setting.style"
                    >
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>

                      <base-input
                          :description="setting['description']"
                          :required="setting['required']"
                          v-if="
                          setting['type'] == 'number' &&
                          setting['formula'] == undefined
                        "
                          :label="setting['label']"
                          placeholder=""
                          input-classes="form-control"
                          type="number"
                          v-model="settingsModel[setting['code']]"
                          @change="checkEmptyNumber(setting['code'])"
                          :disabled="
                          (setting['disableEdit'] != undefined
                            ? setting['disableEdit']
                            : false) || checkDisabled(setting)
                        "
                      />
                      <base-input
                          :description="setting['description']"
                          :required="setting['required']"
                          v-else
                          :label="setting['label']"
                          placeholder=""
                          input-classes="form-control"
                          type="number"
                          :value="
                          computeFormula(
                            setting['formula'],
                            setting['code'],
                            setting['valueCondition'] == undefined
                              ? null
                              : setting['valueCondition'],
                            setting['label']
                          )
                        "
                          v-model="settingsModel[setting['code']]"
                          :disabled="true"
                      />
                    </div>

                    <base-input
                        :description="setting['description']"
                        :required="setting['required']"
                        v-if="setting['type'] == 'select'"
                        :label="setting['label']"
                        :style="setting.style"
                    >
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>

                      <a-select
                          style="width: 100%"
                          placeholder="Please select"
                          optionFilterProp="children"
                          v-model="settingsModel[setting['code']]"
                          :disabled="checkDisabled(setting)"
                      >
                        <a-select-option
                            v-for="option in setting['options']"
                            :key="option.id"
                            :value="option.id"
                        >{{ option.name }}</a-select-option
                        >
                      </a-select>
                    </base-input>

                    <div
                        v-if="setting['type'] == 'boolean'"
                        :style="setting.style"
                    >
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>
                      <base-checkbox
                          class="mb-3"
                          v-model="settingsModel[setting['code']]"
                          :checked="settingsModel[setting['code']]"
                          :modelName="setting['code']"
                          :disabled="checkDisabled(setting)"
                      >
                        {{ setting['label'] }}
                      </base-checkbox>
                    </div>

                    <base-input
                        :description="setting['description']"
                        :required="setting['required']"
                        v-if="setting['type'] == 'multiple_select'"
                        :label="setting['label']"
                        :style="setting.style"
                    >
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>

                      <a-select
                          mode="multiple"
                          style="width: 100%"
                          placeholder="Please select"
                          optionFilterProp="children"
                          v-model="settingsModel[setting['code']]"
                          :disabled="checkDisabled(setting)"
                      >
                        <a-select-option
                            v-for="option in setting['options']"
                            :key="option.id"
                            :value="option.id"
                        >{{ option.name }}</a-select-option
                        >
                      </a-select>
                    </base-input>

                    <!-- HHHHH -->
                    <base-input
                        :description="setting['description']"
                        :required="setting['required']"
                        v-if="setting['type'] == 'date'"
                        :label="setting['label']"
                        :style="setting.style"
                    >
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>

                      <br /><a-date-picker
                        @change="
                          (date, dateString) =>
                            handleDatePickerChange(
                              date,
                              dateString,
                              setting['code']
                            )
                        "
                        v-model="settingsModel[setting['code']]"
                        :format="dateFormat"
                        input-classes="form-control"
                        :disabled="checkDisabled(setting)"
                    />
                    </base-input>

                    <base-input
                        :description="setting['description']"
                        :required="setting['required']"
                        v-if="setting['type'] == 'time'"
                        :label="setting['label']"
                        :style="setting.style"
                    >
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>

                      <br /><a-time-picker
                        valueFormat="H:mm:ss"
                        format="H:mm:ss"
                        v-model="settingsModel[setting['code']]"
                        input-classes="form-control"
                        :disabled="checkDisabled(setting)"
                    />
                    </base-input>

                    <base-input
                        :description="setting['description']"
                        :required="setting['required']"
                        v-if="setting['type'] == 'radio'"
                        :label="setting['label']"
                        :style="setting.style"
                    >
                      <br />
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>
                      <a-radio-group v-model="settingsModel[setting['code']]">
                        <a-radio
                            v-if="option.description == null"
                            v-for="option in setting['options']"
                            :style="radioStyle"
                            :value="option.id"
                            :key="option.id"
                            :disabled="checkDisabled(setting)"
                        >
                          {{ option.name }}
                        </a-radio>
                        <a-radio
                            v-if="option.description != null"
                            v-for="option in setting['options']"
                            :style="radioStyle2"
                            :value="option.id"
                            :key="option.id"
                            :disabled="checkDisabled(setting)"
                        >
                          <b>{{ option.name }}</b>
                          <p style="padding-left: 25px">
                            <small>{{ option.description }}</small>
                          </p>
                        </a-radio>
                      </a-radio-group>
                    </base-input>

                    <base-input
                        :description="setting['description']"
                        :required="setting['required']"
                        v-if="setting['type'] == 'slider'"
                        :label="setting['label']"
                        :style="setting.style"
                    >
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>

                      <div v-if="setting.legend">
                        <br /><span
                          class="badge"
                          style="background-color: #9d3ffa"
                      >&nbsp;&nbsp;&nbsp;&nbsp;</span
                      >
                        {{ setting.legend }}
                      </div>
                      <div v-if="errorMessage[setting.code] != null">
                        <br />
                        <base-alert type="warning" style="height: 50px">
                          {{ errorMessage[setting.code] }}
                        </base-alert>
                        <br />
                      </div>
                      <a-slider
                          :defaultValue="[]"
                          @change="checkSliderValue(setting)"
                          v-model="settingsModel[setting['code']]"
                          :marks="
                          generateMarks(settingsModel[setting.maximumValue])
                        "
                          :step="1"
                          :max="parseInt(settingsModel[setting.maximumValue])"
                          :min="1"
                          range
                          :disabled="checkDisabled(setting)"
                      />
                    </base-input>

                    <base-input
                        :description="setting['description']"
                        v-if="setting['type'] == 'switch'"
                        class="row"
                        :style="setting.style"
                    >
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>
                      <div class="col-md-10">
                        <h3>{{ setting['label'] }}</h3>
                        <small>{{ setting['description'] }}</small>
                      </div>
                      <div class="float-right col-md-2">
                        <a-switch
                            v-model="settingsModel[setting['code']]"
                            :disabled="checkDisabled(setting)"
                        />
                      </div>
                    </base-input>
                  </div>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
        <div v-if="mode == 'cards'" class="dynamic-form" style="width: 100%">
          <a-collapse accordion activeKey="1">
            <a-collapse-panel
                v-for="groups in settingsArray"
                :class="groups.class"
                :style="groups.style"
                :key="groups['sequence']"
                :header="groups.name"
            >
              <div class="row">
                <div
                    v-for="setting in groups['terminalSettings']"
                    :class="setting.class"
                    :key="setting['code']"
                >
                  <div v-if="isVisible(setting) == true">
                    <div
                        v-if="setting['type'] == 'component'"
                        :style="setting.style"
                    >
                      <div
                          :is="setting['componentName']"
                          :defaultValue="settingsModel[setting['code']]"
                          :settingCode="setting['code']"
                          @changeValue="changeValue"
                      ></div>
                    </div>

                    <div
                        v-if="setting['type'] == 'text'"
                        :style="setting.style"
                    >
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>

                      <base-input
                          :description="setting['description']"
                          :required="setting['required']"
                          :label="setting['label']"
                          placeholder=""
                          input-classes="form-control"
                          v-model="settingsModel[setting['code']]"
                          :disabled="checkDisabled(setting) == true"
                      />
                    </div>

                    <div
                        v-if="setting['type'] == 'label'"
                        :style="setting.style"
                    >
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>

                      <h5>{{ setting['label'] }}</h5>
                      <!-- <base-input
                        :description="" :label="subSetting['label']" placeholder="" input-classes="form-control" v-model="settingsModel[subSetting['code']]" /> -->
                    </div>

                    <div v-if="setting['type'] == 'searchable_select'">
                      <searchable-select
                          :subSetting="setting"
                          :disabled="checkDisabled(setting, true)"
                          :key="externalSelect"
                          @updateValues="updateHashValue"
                      ></searchable-select>
                    </div>

                    <div
                        v-if="setting['type'] == 'cascader'"
                        :style="setting.style"
                    >
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>

                      <h5>{{ setting['label'] }}</h5>
                      <!-- :disabled="checkDisabled(setting) == true" -->
                      <a-cascader
                          v-model="settingsModel[setting['code']]"
                          :options="setting['options']"
                          placeholder="Please select category"
                          style="width: 100%"
                          :disabled="checkDisabled(setting) == true"
                      />
                    </div>

                    <div
                        v-if="setting['type'] == 'number'"
                        :style="setting.style"
                    >
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>

                      <base-input
                          :description="setting['description']"
                          :required="setting['required']"
                          v-if="setting['type'] == 'number'"
                          :label="setting['label']"
                          placeholder=""
                          input-classes="form-control"
                          type="number"
                          v-model="settingsModel[setting['code']]"
                          :disabled="
                          checkDisabled(setting) == true ||
                          (setting['disableEdit'] != undefined
                            ? setting['disableEdit']
                            : false)
                        "
                      />
                    </div>

                    <base-input
                        :description="setting['description']"
                        :required="setting['required']"
                        v-if="setting['type'] == 'select'"
                        :label="setting['label']"
                        :style="setting.style"
                    >
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>

                      <a-select
                          style="width: 100%"
                          placeholder="Please select"
                          optionFilterProp="children"
                          v-model="settingsModel[setting['code']]"
                          :disabled="checkDisabled(setting) == true"
                      >
                        <a-select-option
                            v-for="option in setting['options']"
                            :key="option.id"
                            :value="option.id"
                        >{{ option.name }}</a-select-option
                        >
                      </a-select>
                    </base-input>

                    <div
                        v-if="setting['type'] == 'boolean'"
                        :style="setting.style"
                    >
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>
                      <base-checkbox
                          class="mb-3"
                          v-model="settingsModel[setting['code']]"
                          :checked="settingsModel[setting['code']]"
                          :modelName="setting['code']"
                          :disabled="checkDisabled(setting)"
                      >
                        {{ setting['label'] }}
                      </base-checkbox>
                    </div>

                    <base-input
                        :description="setting['description']"
                        :required="setting['required']"
                        v-if="setting['type'] == 'multiple_select'"
                        :label="setting['label']"
                        :style="setting.style"
                    >
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>

                      <a-select
                          mode="multiple"
                          style="width: 100%"
                          placeholder="Please select"
                          optionFilterProp="children"
                          v-model="settingsModel[setting['code']]"
                          :disabled="checkDisabled(setting) == true"
                      >
                        <a-select-option
                            v-for="option in setting['options']"
                            :key="option.id"
                            :value="option.id"
                        >{{ option.name }}</a-select-option
                        >
                      </a-select>
                    </base-input>

                    <base-input
                        :description="setting['description']"
                        :required="setting['required']"
                        v-if="setting['type'] == 'date'"
                        :label="setting['label']"
                        :style="setting.style"
                    >
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>

                      <br /><a-date-picker
                        @change="
                          (date, dateString) =>
                            handleDatePickerChange(
                              date,
                              dateString,
                              setting['code']
                            )
                        "
                        v-model="settingsModel[setting['code']]"
                        :format="dateFormat"
                        input-classes="form-control"
                        :disabled="checkDisabled(setting) == true"
                    />
                    </base-input>

                    <base-input
                        :description="setting['description']"
                        :required="setting['required']"
                        v-if="setting['type'] == 'range'"
                        :label="setting['label']"
                        :style="setting.style"
                    >
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>

                      <br /><a-range-picker
                        v-model="settingsModel[setting['code']]"
                        input-classes="form-control"
                    />
                    </base-input>

                    <base-input
                        :description="setting['description']"
                        :required="setting['required']"
                        v-if="setting['type'] == 'time'"
                        :label="setting['label']"
                        :style="setting.style"
                    >
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>

                      <br /><a-time-picker
                        valueFormat="H:mm:ss"
                        format="H:mm:ss"
                        v-model="settingsModel[setting['code']]"
                        input-classes="form-control"
                        :disabled="checkDisabled(setting)"
                    />
                    </base-input>

                    <base-input
                        :description="setting['description']"
                        :required="setting['required']"
                        v-if="setting['type'] == 'radio'"
                        :label="setting['label']"
                        :style="setting.style"
                    >
                      <br />
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>
                      <a-radio-group v-model="settingsModel[setting['code']]">
                        <a-radio
                            v-if="option.description == null"
                            v-for="option in setting['options']"
                            :style="radioStyle"
                            :value="option.id"
                            :key="option.id"
                            :disabled="checkDisabled(setting)"
                        >
                          {{ option.name }}
                        </a-radio>
                        <a-radio
                            v-if="option.description != null"
                            v-for="option in setting['options']"
                            :style="radioStyle2"
                            :value="option.id"
                            :key="option.id"
                        >
                          <b>{{ option.name }}</b>
                          <p style="padding-left: 25px">
                            <small>{{ option.description }}</small>
                          </p>
                        </a-radio>
                      </a-radio-group>
                    </base-input>

                    <base-input
                        :description="setting['description']"
                        :required="setting['required']"
                        v-if="setting['type'] == 'slider'"
                        :label="setting['label']"
                        :style="setting.style"
                    >
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>

                      <div v-if="setting.legend">
                        <br /><span
                          class="badge"
                          style="background-color: #9d3ffa"
                      >&nbsp;&nbsp;&nbsp;&nbsp;</span
                      >
                        {{ setting.legend }}
                      </div>
                      <div v-if="errorMessage[setting.code] != null">
                        <br />
                        <base-alert type="warning" style="height: 50px">
                          {{ errorMessage[setting.code] }}
                        </base-alert>
                        <br />
                      </div>
                      <a-slider
                          :defaultValue="[]"
                          @change="checkSliderValue(setting)"
                          v-model="settingsModel[setting['code']]"
                          :marks="
                          generateMarks(settingsModel[setting.maximumValue])
                        "
                          :step="1"
                          :max="parseInt(settingsModel[setting.maximumValue])"
                          :min="1"
                          range
                          :disabled="checkDisabled(setting)"
                      />
                    </base-input>

                    <base-input
                        :description="setting['description']"
                        v-if="setting['type'] == 'switch'"
                        class="row"
                        :style="setting.style"
                    >
                      <h3 v-if="setting['header']">
                        <br />
                        {{ setting['header'] }}
                      </h3>
                      <div class="col-md-10">
                        <p>{{ setting['label'] }}</p>
                        <small>{{ setting['description'] }}</small>
                      </div>
                      <div class="float-right col-md-2">
                        <a-switch
                            v-model="settingsModel[setting['code']]"
                            :disabled="checkDisabled(setting) == true"
                        />
                      </div>
                    </base-input>
                  </div>
                </div>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </div>
        <div v-if="mode == 'list'" class="row col-md-12">
          <div
              v-for="groups in settingsArray"
              :class="groups.class"
              :style="groups.style"
              :key="groups['sequence']"
          >
            <div
                v-if="
                (groups['terminalSettings'] == null) == true &&
                !getConditionResult(groups).includes(false)
              "
                class="row"
            >
              <h2 class="col-md-12" v-if="groups['name'] != undefined">
                {{ groups['name'] }}
              </h2>
              <div v-if="groups['mode'] == 'list'">
                <div
                    v-for="subGroup in groups['subGroups']"
                    :class="subGroup.class"
                    :style="subGroup.style"
                    :key="subGroup['code']"
                >
                  <div class="col-md-12">
                    <h3>{{ subGroup.name }}</h3>
                  </div>
                  <div
                      v-for="subSetting in subGroup['terminalSettings']"
                      :class="subSetting.class"
                      :key="subSetting['code']"
                  >
                    <div v-if="isVisible(subSetting) == true">
                      <div
                          v-if="subSetting['type'] == 'text'"
                          :style="subSetting.style"
                      >
                        <h3 v-if="subSetting['header']">
                          <br />
                          {{ subSetting['header'] }}
                        </h3>
                        <base-input
                            :description="subSetting['description']"
                            :required="subSetting['required']"
                            :label="subSetting['label']"
                            placeholder=""
                            input-classes="form-control"
                            v-model="settingsModel[subSetting['code']]"
                        />
                      </div>

                      <div
                          v-if="subSetting['type'] == 'label'"
                          :style="subSetting.style"
                      >
                        <h3 v-if="subSetting['header']">
                          <br />
                          {{ subSetting['header'] }}
                        </h3>
                        <h5>{{ subSetting['label'] }}</h5>
                        <!-- <base-input
                          :description="" :label="subSetting['label']" placeholder="" input-classes="form-control" v-model="settingsModel[subSetting['code']]" /> -->
                      </div>

                      <div
                          v-if="subSetting['type'] == 'number'"
                          :style="subSetting.style"
                      >
                        <h3 v-if="subSetting['header']">
                          <br />
                          {{ subSetting['header'] }}
                        </h3>
                        <base-input
                            :description="subSetting['description']"
                            :required="subSetting['required']"
                            v-if="subSetting['type'] == 'number'"
                            :label="subSetting['label']"
                            placeholder=""
                            input-classes="form-control"
                            type="number"
                            v-model="settingsModel[subSetting['code']]"
                            :disabled="
                            subSetting['disableEdit'] != undefined
                              ? subSetting['disableEdit']
                              : false || checkDisabled(subSetting)
                          "
                        />
                      </div>

                      <base-input
                          :description="subSetting['description']"
                          :required="subSetting['required']"
                          v-if="subSetting['type'] == 'select'"
                          :label="subSetting['label']"
                          :style="subSetting.style"
                      >
                        <h3 v-if="subSetting['header']">
                          <br />
                          {{ subSetting['header'] }}
                        </h3>
                        <a-select
                            style="width: 100%"
                            placeholder="Please select"
                            optionFilterProp="children"
                            v-model="settingsModel[subSetting['code']]"
                            :disabled="checkDisabled(subSetting)"
                        >
                          <a-select-option
                              v-for="option in subSetting['options']"
                              :key="option.id"
                              :value="option.id"
                          >{{ option.name }}</a-select-option
                          >
                        </a-select>
                      </base-input>

                      <div
                          v-if="subSetting['type'] == 'boolean'"
                          :style="subSetting.style"
                      >
                        <h3 v-if="subSetting['header']">
                          <br />
                          {{ subSetting['header'] }}
                        </h3>
                        <base-checkbox
                            class="mb-3"
                            v-model="settingsModel[subSetting['code']]"
                            :checked="settingsModel[subSetting['code']]"
                            :modelName="subSetting['code']"
                            :disabled="checkDisabled(subSetting)"
                        >
                          {{ subSetting['label'] }}
                        </base-checkbox>
                      </div>

                      <base-input
                          :description="subSetting['description']"
                          :required="subSetting['required']"
                          v-if="subSetting['type'] == 'multiple_select'"
                          :label="subSetting['label']"
                          :style="subSetting.style"
                      >
                        <h3 v-if="subSetting['header']">
                          <br />
                          {{ subSetting['header'] }}
                        </h3>
                        <a-select
                            mode="multiple"
                            style="width: 100%"
                            placeholder="Please select"
                            optionFilterProp="children"
                            v-model="settingsModel[subSetting['code']]"
                            :disabled="checkDisabled(subSetting)"
                        >
                          <a-select-option
                              v-for="option in subSetting['options']"
                              :key="option.id"
                              :value="option.id"
                          >{{ option.name }}</a-select-option
                          >
                        </a-select>
                      </base-input>

                      <div v-if="subSetting['type'] == 'searchable_select'">
                        <searchable-select
                            :subSetting="subSetting"
                        ></searchable-select>
                      </div>

                      <base-input
                          :description="subSetting['description']"
                          :required="subSetting['required']"
                          v-if="subSetting['type'] == 'date'"
                          :label="subSetting['label']"
                          :style="subSetting.style"
                      >
                        <h3 v-if="subSetting['header']">
                          <br />
                          {{ subSetting['header'] }}
                        </h3>
                        <br /><a-date-picker
                          @change="
                            (date, dateString) =>
                              handleDatePickerChange(
                                date,
                                dateString,
                                subSetting['code']
                              )
                          "
                          v-model="settingsModel[subSetting['code']]"
                          :format="dateFormat"
                          input-classes="form-control"
                          :disabled="checkDisabled(subSetting)"
                      />
                      </base-input>

                      <base-input
                          :description="subSetting['description']"
                          :required="subSetting['required']"
                          v-if="subSetting['type'] == 'time'"
                          :label="subSetting['label']"
                          :style="subSetting.style"
                      >
                        <h3 v-if="subSetting['header']">
                          <br />
                          {{ subSetting['header'] }}
                        </h3>
                        <br /><a-time-picker
                          valueFormat="H:mm:ss"
                          format="H:mm:ss"
                          v-model="settingsModel[subSetting['code']]"
                          input-classes="form-control"
                          :disabled="checkDisabled(subSetting)"
                      />
                      </base-input>

                      <base-input
                          :description="subSetting['description']"
                          :required="subSetting['required']"
                          v-if="subSetting['type'] == 'radio'"
                          :label="subSetting['label']"
                          :style="subSetting.style"
                      >
                        <br />
                        <h3 v-if="subSetting['header']">
                          <br />
                          {{ subSetting['header'] }}
                        </h3>
                        <a-radio-group
                            v-model="settingsModel[subSetting['code']]"
                        >
                          <a-radio
                              v-if="option.description == null"
                              v-for="option in subSetting['options']"
                              :style="radioStyle"
                              :value="option.id"
                              :key="option.id"
                              :disabled="checkDisabled(subSetting)"
                          >
                            {{ option.name }}
                          </a-radio>
                          <a-radio
                              v-if="option.description != null"
                              v-for="option in subSetting['options']"
                              :style="radioStyle2"
                              :value="option.id"
                              :key="option.id"
                          >
                            <b>{{ option.name }}</b>
                            <p style="padding-left: 25px">
                              <small>{{ option.description }}</small>
                            </p>
                          </a-radio>
                        </a-radio-group>
                      </base-input>

                      <base-input
                          :description="subSetting['description']"
                          :required="subSetting['required']"
                          v-if="subSetting['type'] == 'slider'"
                          :label="subSetting['label']"
                          :style="subSetting.style"
                      >
                        <h3 v-if="subSetting['header']">
                          <br />
                          {{ subSetting['header'] }}
                        </h3>
                        <div v-if="subSetting.legend">
                          <br /><span
                            class="badge"
                            style="background-color: #9d3ffa"
                        >&nbsp;&nbsp;&nbsp;&nbsp;</span
                        >
                          {{ subSetting.legend }}
                        </div>
                        <div v-if="errorMessage[subSetting.code] != null">
                          <br />
                          <base-alert type="warning" style="height: 50px">
                            {{ errorMessage[subSetting.code] }}
                          </base-alert>
                          <br />
                        </div>
                        <a-slider
                            :defaultValue="[]"
                            @change="checkSliderValue(subSetting)"
                            v-model="settingsModel[subSetting['code']]"
                            :marks="
                            generateMarks(
                              settingsModel[subSetting.maximumValue]
                            )
                          "
                            :step="1"
                            :max="
                            parseInt(settingsModel[subSetting.maximumValue])
                          "
                            :min="1"
                            range
                            :disabled="checkDisabled(subSetting)"
                        />
                      </base-input>

                      <base-input
                          :description="subSetting['description']"
                          v-if="subSetting['type'] == 'switch'"
                          class="row"
                          :style="subSetting.style"
                      >
                        <h3 v-if="subSetting['header']">
                          <br />
                          {{ subSetting['header'] }}
                        </h3>
                        <div class="col-md-10">
                          <h3>{{ subSetting['label'] }}</h3>
                        </div>
                        <div class="float-right col-md-2">
                          <a-switch
                              v-model="settingsModel[subSetting['code']]"
                              :disabled="checkDisabled(subSetting)"
                          />
                        </div>
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <a-tabs type="card" default-active-key="1">
                  <a-tab-pane
                      v-for="subGroup in groups['subGroups']"
                      :tab="subGroup['name']"
                      :key="subGroup['sequence']"
                  >
                    <div
                        v-for="subSetting in subGroup['terminalSettings']"
                        :key="subSetting['code']"
                    >
                      <div v-if="isVisible(subSetting) == true">
                        <div
                            v-if="subSetting['type'] == 'text'"
                            :style="subSetting.style"
                        >
                          <h3 v-if="subSetting['header']">
                            <br />
                            {{ subSetting['header'] }}
                          </h3>
                          <base-input
                              :description="subSetting['description']"
                              :required="subSetting['required']"
                              :label="subSetting['label']"
                              placeholder=""
                              input-classes="form-control"
                              v-model="settingsModel[subSetting['code']]"
                          />
                        </div>

                        <div
                            v-if="subSetting['type'] == 'label'"
                            :style="subSetting.style"
                        >
                          <h3 v-if="subSetting['header']">
                            <br />
                            {{ subSetting['header'] }}
                          </h3>
                          <h5>{{ subSetting['label'] }}</h5>
                          <!-- <base-input
                            :description="" :label="subSetting['label']" placeholder="" input-classes="form-control" v-model="settingsModel[subSetting['code']]" /> -->
                        </div>

                        <div
                            v-if="subSetting['type'] == 'number'"
                            :style="subSetting.style"
                        >
                          <h3 v-if="subSetting['header']">
                            <br />
                            {{ subSetting['header'] }}
                          </h3>
                          <base-input
                              :description="subSetting['description']"
                              :required="subSetting['required']"
                              v-if="subSetting['type'] == 'number'"
                              :label="subSetting['label']"
                              placeholder=""
                              input-classes="form-control"
                              type="number"
                              v-model="settingsModel[subSetting['code']]"
                              :disabled="checkDisabled(subSetting)"
                          />
                        </div>

                        <base-input
                            :description="subSetting['description']"
                            :required="subSetting['required']"
                            v-if="subSetting['type'] == 'select'"
                            :label="subSetting['label']"
                            :style="subSetting.style"
                        >
                          <h3 v-if="subSetting['header']">
                            <br />
                            {{ subSetting['header'] }}
                          </h3>
                          <a-select
                              style="width: 100%"
                              placeholder="Please select"
                              optionFilterProp="children"
                              v-model="settingsModel[subSetting['code']]"
                          >
                            <a-select-option
                                v-for="option in subSetting['options']"
                                :key="option.id"
                                :value="option.id"
                            >{{ option.name }}</a-select-option
                            >
                          </a-select>
                        </base-input>

                        <div
                            v-if="subSetting['type'] == 'boolean'"
                            :style="subSetting.style"
                        >
                          <h3 v-if="subSetting['header']">
                            <br />
                            {{ subSetting['header'] }}
                          </h3>
                          <base-checkbox
                              class="mb-3"
                              v-model="settingsModel[subSetting['code']]"
                              :checked="settingsModel[subSetting['code']]"
                              :modelName="subSetting['code']"
                              :disabled="checkDisabled(subSetting)"
                          >
                            {{ subSetting['label'] }}
                          </base-checkbox>
                        </div>

                        <base-input
                            :description="subSetting['description']"
                            :required="subSetting['required']"
                            v-if="subSetting['type'] == 'multiple_select'"
                            :label="subSetting['label']"
                            :style="subSetting.style"
                        >
                          <h3 v-if="subSetting['header']">
                            <br />
                            {{ subSetting['header'] }}
                          </h3>
                          <!-- showSearch :notFoundContent="null" @search="handleSearch"  -->
                          <a-select
                              mode="multiple"
                              style="width: 100%"
                              placeholder="Please select"
                              optionFilterProp="children"
                              v-model="settingsModel[subSetting['code']]"
                              :disabled="checkDisabled(subSetting)"
                          >
                            <a-select-option
                                v-for="option in subSetting['options']"
                                :key="option.id"
                                :value="option.id"
                            >{{ option.name }}</a-select-option
                            >
                          </a-select>
                        </base-input>

                        <base-input
                            :description="subSetting['description']"
                            :required="subSetting['required']"
                            v-if="subSetting['type'] == 'date'"
                            :label="subSetting['label']"
                            :style="subSetting.style"
                        >
                          <h3 v-if="subSetting['header']">
                            <br />
                            {{ subSetting['header'] }}
                          </h3>
                          <br /><a-date-picker
                            @change="
                              (date, dateString) =>
                                handleDatePickerChange(
                                  date,
                                  dateString,
                                  subSetting['code']
                                )
                            "
                            v-model="settingsModel[subSetting['code']]"
                            :format="dateFormat"
                            input-classes="form-control"
                        />
                        </base-input>

                        <base-input
                            :description="subSetting['description']"
                            :required="subSetting['required']"
                            v-if="subSetting['type'] == 'time'"
                            :label="subSetting['label']"
                            :style="subSetting.style"
                        >
                          <h3 v-if="subSetting['header']">
                            <br />
                            {{ subSetting['header'] }}
                          </h3>
                          <br /><a-time-picker
                            valueFormat="H:mm:ss"
                            format="H:mm:ss"
                            v-model="settingsModel[subSetting['code']]"
                            input-classes="form-control"
                        />
                        </base-input>

                        <base-input
                            :description="subSetting['description']"
                            :required="subSetting['required']"
                            v-if="subSetting['type'] == 'radio'"
                            :label="subSetting['label']"
                            :style="subSetting.style"
                        >
                          <br />
                          <h3 v-if="subSetting['header']">
                            <br />
                            {{ subSetting['header'] }}
                          </h3>
                          <a-radio-group
                              v-model="settingsModel[subSetting['code']]"
                          >
                            <a-radio
                                v-if="option.description == null"
                                v-for="option in subSetting['options']"
                                :style="radioStyle"
                                :value="option.id"
                                :key="option.id"
                            >
                              {{ option.name }}
                            </a-radio>
                            <a-radio
                                v-if="option.description != null"
                                v-for="option in subSetting['options']"
                                :style="radioStyle2"
                                :value="option.id"
                                :key="option.id"
                            >
                              <b>{{ option.name }}</b>
                              <p style="padding-left: 25px">
                                <small>{{ option.description }}</small>
                              </p>
                            </a-radio>
                          </a-radio-group>
                        </base-input>

                        <base-input
                            :description="subSetting['description']"
                            :required="subSetting['required']"
                            v-if="subSetting['type'] == 'slider'"
                            :label="subSetting['label']"
                            :style="subSetting.style"
                        >
                          <h3 v-if="subSetting['header']">
                            <br />
                            {{ subSetting['header'] }}
                          </h3>
                          <div v-if="subSetting.legend">
                            <br /><span
                              class="badge"
                              style="background-color: #9d3ffa"
                          >&nbsp;&nbsp;&nbsp;&nbsp;</span
                          >
                            {{ subSetting.legend }}
                          </div>
                          <div v-if="errorMessage[subSetting.code] != null">
                            <br />
                            <base-alert type="warning" style="height: 50px">
                              {{ errorMessage[subSetting.code] }}
                            </base-alert>
                            <br />
                          </div>
                          <a-slider
                              :defaultValue="[]"
                              @change="checkSliderValue(subSetting)"
                              v-model="settingsModel[subSetting['code']]"
                              :marks="
                              generateMarks(
                                settingsModel[subSetting.maximumValue]
                              )
                            "
                              :step="1"
                              :max="
                              parseInt(settingsModel[subSetting.maximumValue])
                            "
                              :min="1"
                              range
                          />
                        </base-input>

                        <base-input
                            :description="subSetting['description']"
                            v-if="subSetting['type'] == 'switch'"
                            class="row"
                            :style="subSetting.style"
                        >
                          <h3 v-if="subSetting['header']">
                            <br />
                            {{ subSetting['header'] }}
                          </h3>
                          <div class="col-md-10">
                            <h3>{{ subSetting['label'] }}</h3>
                          </div>
                          <div class="float-right col-md-2">
                            <a-switch
                                v-model="settingsModel[subSetting['code']]"
                            />
                          </div>
                        </base-input>
                      </div>
                    </div>
                  </a-tab-pane>
                </a-tabs>
              </div>
            </div>
            <div
                v-if="
                (groups['terminalSettings'] != null) == true &&
                !getConditionResult(groups).includes(false)
              "
                class="row"
            >
              <h2 class="col-md-12" v-if="groups['name'] != undefined">
                {{ groups['name'] }}
              </h2>
              <div
                  v-for="setting in groups['terminalSettings']"
                  :class="setting.class"
                  :key="setting['code']"
              >
                <div v-if="isVisible(setting) == true">
                  <!-- :class="[{indent: withCondition(setting['code'],1) != null}]" -->
                  <!-- <base-input
                    :description="" v-if="setting['type'] == 'text'" :label="setting['label']" placeholder="" input-classes="form-control" v-model="settingsModel[setting['code']]" /> -->
                  <div
                      v-if="setting['type'] == 'component'"
                      :style="setting.style"
                  >
                    <div
                        :is="setting['componentName']"
                        :defaultValue="settingsModel[setting['code']]"
                        :settingCode="setting['code']"
                        @changeValue="changeValue"
                    ></div>
                  </div>

                  <div v-if="setting['type'] == 'text'" :style="setting.style">
                    <h3 v-if="setting['header']">
                      <br />
                      {{ setting['header'] }}
                    </h3>
                    <base-input
                        :description="setting['description']"
                        :required="setting['required']"
                        :label="setting['label']"
                        placeholder=""
                        input-classes="form-control"
                        v-model="settingsModel[setting['code']]"
                    />
                  </div>

                  <div v-if="setting['type'] == 'label'" :style="setting.style">
                    <h3 v-if="setting['header']">
                      <br />
                      {{ setting['header'] }}
                    </h3>
                    <h5>{{ setting['label'] }}</h5>
                    <!-- <base-input
                      :description="" :label="subSetting['label']" placeholder="" input-classes="form-control" v-model="settingsModel[subSetting['code']]" /> -->
                  </div>

                  <div
                      v-if="setting['type'] == 'number'"
                      :style="setting.style"
                  >
                    <h3 v-if="setting['header']">
                      <br />
                      {{ setting['header'] }}
                    </h3>
                    <base-input
                        :description="setting['description']"
                        :required="setting['required']"
                        v-if="setting['type'] == 'number'"
                        :label="setting['label']"
                        placeholder=""
                        input-classes="form-control"
                        type="number"
                        v-model="settingsModel[setting['code']]"
                        :disabled="checkDisabled(setting)"
                    />
                  </div>

                  <base-input
                      :description="setting['description']"
                      :required="setting['required']"
                      v-if="setting['type'] == 'select'"
                      :label="setting['label']"
                      :style="setting.style"
                  >
                    <h3 v-if="setting['header']">
                      <br />
                      {{ setting['header'] }}
                    </h3>
                    <a-select
                        style="width: 100%"
                        placeholder="Please select"
                        optionFilterProp="children"
                        v-model="settingsModel[setting['code']]"
                    >
                      <a-select-option
                          v-for="option in setting['options']"
                          :key="option.id"
                          :value="option.id"
                      >{{ option.name }}</a-select-option
                      >
                    </a-select>
                  </base-input>

                  <div
                      v-if="setting['type'] == 'boolean'"
                      :style="setting.style"
                  >
                    <h3 v-if="setting['header']">
                      <br />
                      {{ setting['header'] }}
                    </h3>
                    <base-checkbox
                        class="mb-3"
                        v-model="settingsModel[setting['code']]"
                        :checked="settingsModel[setting['code']]"
                        :modelName="setting['code']"
                        :disabled="checkDisabled(setting)"
                    >
                      {{ setting['label'] }}
                    </base-checkbox>
                  </div>

                  <base-input
                      :description="setting['description']"
                      :required="setting['required']"
                      v-if="setting['type'] == 'multiple_select'"
                      :label="setting['label']"
                      :style="setting.style"
                  >
                    <h3 v-if="setting['header']">
                      <br />
                      {{ setting['header'] }}
                    </h3>
                    <a-select
                        mode="multiple"
                        style="width: 100%"
                        placeholder="Please select"
                        optionFilterProp="children"
                        v-model="settingsModel[setting['code']]"
                    >
                      <a-select-option
                          v-for="option in setting['options']"
                          :key="option.id"
                          :value="option.id"
                      >{{ option.name }}</a-select-option
                      >
                    </a-select>
                  </base-input>

                  <div v-if="setting['type'] == 'searchable_select'">
                    <searchable-select
                        :subSetting="setting"
                    ></searchable-select>
                  </div>

                  <base-input
                      :description="setting['description']"
                      :required="setting['required']"
                      v-if="setting['type'] == 'date'"
                      :label="setting['label']"
                      :style="setting.style"
                  >
                    <h3 v-if="setting['header']">
                      <br />
                      {{ setting['header'] }}
                    </h3>
                    <br /><a-date-picker
                      @change="
                        (date, dateString) =>
                          handleDatePickerChange(
                            date,
                            dateString,
                            setting['code']
                          )
                      "
                      v-model="settingsModel[setting['code']]"
                      :format="dateFormat"
                      input-classes="form-control"
                  />
                  </base-input>

                  <base-input
                      :description="setting['description']"
                      :required="setting['required']"
                      v-if="setting['type'] == 'range'"
                      :label="setting['label']"
                      :style="setting.style"
                  >
                    <h3 v-if="setting['header']">
                      <br />
                      {{ setting['header'] }}
                    </h3>
                    <br /><a-range-picker
                      v-model="settingsModel[setting['code']]"
                      input-classes="form-control"
                  />
                  </base-input>

                  <base-input
                      :description="setting['description']"
                      :required="setting['required']"
                      v-if="setting['type'] == 'time'"
                      :label="setting['label']"
                      :style="setting.style"
                  >
                    <h3 v-if="setting['header']">
                      <br />
                      {{ setting['header'] }}
                    </h3>
                    <br /><a-time-picker
                      valueFormat="H:mm:ss"
                      format="H:mm:ss"
                      v-model="settingsModel[setting['code']]"
                      input-classes="form-control"
                  />
                  </base-input>

                  <base-input
                      :description="setting['description']"
                      :required="setting['required']"
                      v-if="setting['type'] == 'radio'"
                      :label="setting['label']"
                      :style="setting.style"
                  >
                    <br />
                    <h3 v-if="setting['header']">
                      <br />
                      {{ setting['header'] }}
                    </h3>
                    <a-radio-group v-model="settingsModel[setting['code']]">
                      <a-radio
                          v-if="option.description == null"
                          v-for="option in setting['options']"
                          :style="radioStyle"
                          :value="option.id"
                          :key="option.id"
                      >
                        {{ option.name }}
                      </a-radio>
                      <a-radio
                          v-if="option.description != null"
                          v-for="option in setting['options']"
                          :style="radioStyle2"
                          :value="option.id"
                          :key="option.id"
                      >
                        <b>{{ option.name }}</b>
                        <p style="padding-left: 25px">
                          <small>{{ option.description }}</small>
                        </p>
                      </a-radio>
                    </a-radio-group>
                  </base-input>

                  <base-input
                      :description="setting['description']"
                      :required="setting['required']"
                      v-if="setting['type'] == 'slider'"
                      :label="setting['label']"
                      :style="setting.style"
                  >
                    <h3 v-if="setting['header']">
                      <br />
                      {{ setting['header'] }}
                    </h3>
                    <div v-if="setting.legend">
                      <br /><span
                        class="badge"
                        style="background-color: #9d3ffa"
                    >&nbsp;&nbsp;&nbsp;&nbsp;</span
                    >
                      {{ setting.legend }}
                    </div>
                    <div v-if="errorMessage[setting.code] != null">
                      <br />
                      <base-alert type="warning" style="height: 50px">
                        {{ errorMessage[setting.code] }}
                      </base-alert>
                      <br />
                    </div>
                    <a-slider
                        :defaultValue="[]"
                        @change="checkSliderValue(setting)"
                        v-model="settingsModel[setting['code']]"
                        :marks="
                        generateMarks(settingsModel[setting.maximumValue])
                      "
                        :step="1"
                        :max="parseInt(settingsModel[setting.maximumValue])"
                        :min="1"
                        range
                    />
                  </base-input>

                  <base-input
                      :description="setting['description']"
                      v-if="setting['type'] == 'switch'"
                      class="row"
                      :style="setting.style"
                  >
                    <h3 v-if="setting['header']">
                      <br />
                      {{ setting['header'] }}
                    </h3>
                    <div class="col-md-10">
                      <h3>{{ setting['label'] }}</h3>
                    </div>
                    <div class="float-right col-md-2">
                      <a-switch v-model="settingsModel[setting['code']]" />
                    </div>
                  </base-input>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>

        <!-- <hr class="my-3">
            <h5 class="navbar-heading text-muted">Sample Value</h5> <br>
            {{ settingsModel }}  -->
        <!-- {{ errors }} -->
      </div>
    </a-spin>
  </div>
</template>

<script>
import HTTPRequest from '../controllers/httpRequest.js';
import { mapState, mapActions } from 'vuex';
import SearchableSelect from './SearchableSelect';
import ValidateField from '../components/validateFormUtils.js';
import _ from 'lodash';
var conditionResult = [];

import moment from 'moment';

export default {
  components: {
    SearchableSelect
  },
  props: {
    formData: {
      type: Object,
      default: null
    },
    membershipPrograms: {
      type: Array,
      default: () => []
    },
    membershipLevels: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },

  mounted: function () {
    // console.log(this.$attrs.formSettings)
    // this.settingsModel = this.$attrs.formSettings
    this.convertedJson = this.$attrs.convertedJson;
    this.formDisabled = this.$attrs.disabled;
    if (this.$attrs.showErrors != null)
      this.showErrors = this.$attrs.showErrors;
    if (this.disabled == true) {
      this.showErrors = false;
    }
    if (this.convertedJson != null) {
      this.processJSON();
    }
  },
  computed: {
    ...mapState([
      'currentProfile',
      'currentApiURL',
      'endPoint',
      'parameters',
      'categoriesOptions'
    ])
    // dynamicComponent(path) {
    //   return () => import(path);
    // }
    // ...mapState(['endPoint', 'currentProfile', 'parameters', 'serverType']
  },
  data() {
    return {
      numbers: [],
      generatedGroupConditions: [],
      formDisabled: false,
      showErrors: true,
      spinning: false,
      externalSelect: 0,
      externalDisabled: true,
      description: null,
      activeKey: '1',
      settingsGroupHash: {},
      errors: [],
      title: null,
      errorMessage: {},
      activeKey: [1],
      dateFormat: 'MM-DD-YYYY',
      mode: 'list',
      settingsModel: {},
      settingsArray: [],
      subGroupSettingsArray: [],
      convertedJson: null,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
      },
      radioStyle2: {
        display: 'block',
        height: '60px',
        lineHeight: '30px'
      }
    };
  },
  watch: {
    settingsModel: {
      handler: function () {
        var errors = this.validateForm();
        var returnSettings = this.settingsModel;
        this.$emit(
            'valueUpdated',
            returnSettings,
            this.settingsGroupHash,
            errors
        );
        this.externalSelect++;
      },
      deep: true
    }

    // settingsModel(){
    //     var returnSettings = this.settingsModel
    //     this.$emit('valueUpdated', returnSettings)
    // },
  },
  methods: {
    moment,
    updateHashValue(code, value) {
      // this.settingsModel[code] = value;
      this.$set(this.settingsModel, code, value);
    },
    checkEmptyNumber(model) {
      if (this.settingsModel[model] == '') {
        this.settingsModel[model] = 0;
      }
    },
    checkDisabled(setting, external = false) {
      if (this.disabled == true) {
        return true;
      }
      if (setting.code == 'override_intro_date')
          // console.log(setting.disabled)
        var return_value = null;

      if (this.formDisabled) {
        return_value = true;
      } else {
        if (setting.disabled == undefined) {
          return_value = false;
        } else {
          if( setting.disabled === null || typeof setting.disabled !== 'object'  ) {
            return_value = setting.disabled;
          } else {

            var code_checker = setting.disabled.code;
            var value_checker = setting.disabled.value;
            if (this.settingsModel[code_checker] == undefined) {
              return_value = true;
            } else {
              if (
                  this.settingsModel[code_checker].toString() ==
                  value_checker.toString()
              )
                return_value = false;
              else return_value = true;
            }
          }
        }
      }
      return return_value;
    },
    validateForm() {
      this.errors = [];
      this.settingsArray.map((value) => {
        if (value['terminalSettings'] != null) {
          var terminalSettings = value['terminalSettings'];
          for (var j = 0; j < terminalSettings.length; j++) {
            if (terminalSettings[j]['required'] == true  && terminalSettings[j]['publishToMobileAndWeb'] == true) {
              if (!terminalSettings[j]['class'])
                terminalSettings[j]['class'] = 'col-md-12 no-gutter';
              this.errors.push(
                  ValidateField.required(
                      terminalSettings[j]['code'],
                      this.settingsModel[terminalSettings[j]['code']]
                  )
              );
            }
          }
        } else if (value['subGroups'] != null) {
          for (var i = 0; i < value['subGroups'].length; i++) {
            var terminalSettings = value['subGroups'][i]['terminalSettings'];
            for (var j = 0; j < terminalSettings.length; j++) {
              if (!terminalSettings[j]['class'])
                terminalSettings[j]['class'] = 'col-md-12 no-gutter';
              if (terminalSettings[j]['required'] == true) {
                this.errors.push(
                    ValidateField.required(
                        terminalSettings[j]['code'],
                        this.settingsModel[terminalSettings[j]['code']]
                    )
                );
              }
            }
          }
        }
      });

      return (this.errors = _.compact(this.errors));
    },
    addCustomErrors(errors) {
      // To add Custom Errors from parent components
      this.errors = errors;
    },
    withCondition(codeSetting, type) {
      var result = null;
      // console.log(this.settingsArray)
      if (type == 1) {
        this.settingsArray.map((value) => {
          if (value['terminalSettings'] != null) {
            var terminalSettings = value['terminalSettings'];
            // console.log("wow")
            for (var j = 0; j < terminalSettings.length; j++) {
              if (codeSetting == terminalSettings[j]['code']) {
                result =
                    terminalSettings[j]['conditions'] == undefined ||
                    terminalSettings[j]['conditions'].length < 1
                        ? null
                        : terminalSettings[j];
              }
            }
          } else if (value['subGroups'] != null) {
            for (var i = 0; i < value['subGroups'].length; i++) {
              var terminalSettings = value['subGroups'][i]['terminalSettings'];
              for (var j = 0; j < terminalSettings.length; j++) {
                if (codeSetting == terminalSettings[j]['code']) {
                  result =
                      terminalSettings[j]['conditions'] == undefined
                          ? null
                          : terminalSettings[j];
                }
              }
            }
          }
        });
      }
      return result;
    },
    getConditionResult(setting) {
      if (setting['conditions'] != undefined) {
        var c = setting['conditions'];
        for (var i = 0; i < c.length; i++) {
          if (this.settingsModel[c[i]['code']] != undefined) {
            if (c[i]['operator'] == undefined || c[i]['operator'] == '=') {
              conditionResult.push(
                  this.settingsModel[c[i]['code']].toString() ==
                  c[i]['value'].toString()
              );
            } else {
              // check AccountSystemModule for adding new operator
              // operations on data()
              switch (c[i]['operator']) {
                case 'NOT NULL':
                  conditionResult.push(
                      this.settingsModel[c[i]['code']].toString() != null &&
                      this.settingsModel[c[i]['code']].toString() != ''
                  );
                  break;
                case 'IS NULL':
                  conditionResult.push(
                      this.settingsModel[c[i]['code']].toString() == '' ||
                      this.settingsModel[c[i]['code']].toString() == undefined
                  );
                  break;
                case '>':
                  conditionResult.push(
                      parseInt(this.settingsModel[c[i]['code']]) >
                      parseInt(c[i]['value'].toString())
                  );
                  break;
                case '<':
                  conditionResult.push(
                      parseInt(this.settingsModel[c[i]['code']]) <
                      parseInt(c[i]['value'].toString())
                  );
                  break;
                case '>=':
                  conditionResult.push(
                      parseInt(this.settingsModel[c[i]['code']]) >=
                      parseInt(c[i]['value'].toString())
                  );
                  break;
                case '<=':
                  conditionResult.push(
                      parseInt(this.settingsModel[c[i]['code']]) <=
                      parseInt(c[i]['value'].toString())
                  );
                  break;
                case 'IN':
                  // convert value to string
                  const value = c[i]['value'].toString();
                  // change value to array
                  // split by ","
                  // trim space
                  let arr = value.split(',').map(function (value) {
                    return value.trim();
                  });
                  const setting = this.settingsModel[c[i]['code']].toString();
                  conditionResult.push(arr.includes(setting));
                  break;
              }
            }
          } else {
            conditionResult.push(false);
          }
          var checker = this.withCondition(c[i]['code'], 1);
          if (checker != null) this.getConditionResult(checker);
        }
      } else if (setting['groupConditions'] != undefined) {
        let x = 0;
        conditionResult = [];
        while (x < setting['groupConditions'].length) {
          // fetch the condition source\
          switch (setting['groupConditions'][x]['source']) {
            case 'SYSTEM': {
              if (
                  setting['groupConditions'][x]['code'] == 'membership_program'
              ) {
                let y = 0;
                let membershipProgram = null;
                while (y < this.membershipPrograms.length) {
                  if (
                      this.membershipPrograms[y].id ==
                      this.formData.membership_program_id
                  ) {
                    membershipProgram = this.membershipPrograms[y];
                  }
                  y++;
                }
                if (membershipProgram != null) {
                  if (setting['groupConditions'][x]['operator'] == 'NOT NULL') {
                    if (
                        this.formData.membership_program_id == null ||
                        this.formData.membership_program_id == undefined
                    ) {
                      conditionResult.push(false);
                    }
                  }
                  if (setting['groupConditions'][x]['operator'] == 'IS NULL') {
                    if (
                        this.formData.membership_program_id != null ||
                        this.formData.membership_program_id != undefined
                    ) {
                      conditionResult.push(false);
                    }
                  }
                  if (setting['groupConditions'][x]['operator'] == '=') {
                    if (
                        setting['groupConditions'][x]['value'] !=
                        membershipProgram.name
                    ) {
                      conditionResult.push(false);
                    }
                  }
                  if (setting['groupConditions'][x]['operator'] == '!=') {
                    if (
                        this.formData.membership_program_id ==
                        membershipProgram.id
                    ) {
                      conditionResult.push(false);
                    }
                  }
                  if (setting['groupConditions'][x]['operator'] == 'IN') {
                    // convert the values to array
                    const data =
                        setting['groupConditions'][x]['value'].split(',');
                    if (!data.includes(membershipProgram.name)) {
                      conditionResult.push(false);
                    }
                  }
                }
              }
              if (setting['groupConditions'][x]['code'] == 'membership_level') {
                let y = 0;
                let membershipLevel = null;
                while (y < this.membershipLevels.length) {
                  if (
                      this.membershipLevels[y].id ==
                      this.formData.membership_level_id
                  ) {
                    membershipLevel = this.membershipLevels[y];
                  }
                  y++;
                }
                if (membershipLevel != null) {
                  if (setting['groupConditions'][x]['operator'] == 'NOT NULL') {
                    if (
                        this.formData.membership_level_id == null ||
                        this.formData.membership_level_id == undefined
                    ) {
                      conditionResult.push(false);
                    }
                  }
                  if (setting['groupConditions'][x]['operator'] == 'IS NULL') {
                    if (
                        this.formData.membership_level_id != null ||
                        this.formData.membership_level_id != undefined
                    ) {
                      conditionResult.push(false);
                    }
                  }
                  if (setting['groupConditions'][x]['operator'] == '=') {
                    if (
                        setting['groupConditions'][x]['value'] !=
                        membershipLevel.name
                    ) {
                      conditionResult.push(false);
                    }
                  }
                  if (setting['groupConditions'][x]['operator'] == '!=') {
                    if (
                        membershipLevel.name ==
                        setting['groupConditions'][x]['value']
                    ) {
                      conditionResult.push(false);
                    }
                  }
                  if (setting['groupConditions'][x]['operator'] == 'IN') {
                    // convert the values to array
                    const data =
                        setting['groupConditions'][x]['value'].split(',');
                    if (!data.includes(membershipLevel.name)) {
                      conditionResult.push(false);
                    }
                  }
                }
              }
              break;
            }
            case 'CUSTOM_FIELDS': {
              // WIP for custom fields
              if (setting['groupConditions'][x]['operator'] == 'NOT NULL') {
                if (
                    this.formData.meta[setting['groupConditions'][x]['code']] ==
                    null ||
                    this.formData.membership_program_id == undefined
                ) {
                  conditionResult.push(false);
                }
              }
              if (setting['groupConditions'][x]['operator'] == 'IS NULL') {
                if (
                    this.formData.meta[setting['groupConditions'][x]['code']] !=
                    null ||
                    this.formData.meta[setting['groupConditions'][x]['code']] !=
                    undefined
                ) {
                  conditionResult.push(false);
                }
              }
              if (setting['groupConditions'][x]['operator'] == '=') {
                if (
                    setting['groupConditions'][x]['value'] !=
                    this.formData.meta[setting['groupConditions'][x]['code']]
                ) {
                  conditionResult.push(false);
                }
              }
              if (setting['groupConditions'][x]['operator'] == '!=') {
                if (
                    this.formData.meta[setting['groupConditions'][x]['code']] ==
                    this.formData.meta[setting['groupConditions'][x]['value']]
                ) {
                  conditionResult.push(false);
                }
              }
              if (setting['groupConditions'][x]['operator'] == 'IN') {
                // convert the values to array
                const data = setting['groupConditions'][x]['value'].split(',');
                if (
                    !data.includes(
                        this.formData.meta[setting['groupConditions'][x]['code']]
                    )
                ) {
                  conditionResult.push(false);
                }
              }
              console.log('process system custom fields');
              break;
            }
            default:
              console.log('unable to process group conditions');
          }
          x++;
        }
      } else {
        conditionResult = [];
        conditionResult.push(true);
      }
      //  this.conditions.push(result)
      return conditionResult;
    },
    isVisible(setting) {
      var result = this.withCondition(setting['code'], 1);

      if (result != null) {
        conditionResult = [];
        var conditions = this.getConditionResult(setting);
        if (conditions.includes(false)) return false;
        else return true;
      } else {
        conditionResult = [];
        return true;
      }
    },

    generateMarks(maxValue) {
      var generatedMark = {};
      for (var i = 1; i <= maxValue; i++) {
        generatedMark[i] = '0';
      }
      return generatedMark;
    },

    checkSliderValue(setting) {
      var slider_value = this.settingsModel[setting.code];
      var slide_value_array = Array.from(
          { length: slider_value[1] - slider_value[0] + 1 },
          (v, k) => k + slider_value[0]
      );
      // console.log(slide_value_array)
      var valid = [];

      if (setting.validator != undefined) {
        for (var i = 0; i < setting.validator.length; i++) {
          var checker = this.settingsModel[setting.validator[i].code];
          if (checker != undefined) {
            if (slide_value_array.includes(checker[0])) {
              valid.push(false);
            } else {
              valid.push(true);
            }

            if (slide_value_array.includes(checker[1])) {
              valid.push(false);
            } else {
              valid.push(true);
            }
          }
        }

        if (valid.includes(false)) {
          // this.settingsModel[setting.code] = []
          this.errorMessage[setting.code] =
              'Invalid range selected. Values must not overlap';
        } else {
          this.errorMessage[setting.code] = null;
        }
      }
    },

    async processJSON() {
      //  Add parameters

      //console.log(this.convertedJson instanceof Object)
      //console.log(this.convertedJson)
      this.spinning = true;
      var groups = [];
      if (this.convertedJson.constructor.name == 'String') {
        this.mode = JSON.parse(this.convertedJson)['mode'];
        this.title = JSON.parse(this.convertedJson)['title'];
        this.description = JSON.parse(this.convertedJson)['description'];
        groups = JSON.parse(this.convertedJson)['groups'];
        // console.log('I am here');
      } else {
        this.mode = this.convertedJson['mode'];
        this.title = this.convertedJson['title'];
        this.description = this.convertedJson['description'];
        groups = this.convertedJson['groups'];
        // console.log(this.convertedJson.constructor.name)
        // console.log('ANDITO KA!!');
      }

      groups.sort(function (a, b) {
        return a.sequence - b.sequence;
      });
      for (var j = 0; j < groups.length; j++) {
        //console.log(groups[]["name"])
        try {
          if (groups[j]['class'] == undefined)
            groups[j]['class'] = 'col-md-12 no-gutter';
        } catch (e) {
          console.log('Error on assigning group class: ', e);
        }
        var types = [
          'text',
          'number',
          'select',
          'searchable_select',
          'multiple_select',
          'boolean',
          'date',
          'time',
          'radio',
          'switch',
          'slider',
          'label',
          'range',
          'cascader',
          'component'
        ];

        var subgroups = groups[j]['subGroups'];
        if (subgroups != undefined) {
          // console.log("OKAY NAMAN YUNG SUBGROUPS!!")
          // groups.splice(j, 1);
          var subSettingArray = [];
          var settingKeysArray = [];

          for (var x = 0; x < subgroups.length; x++) {
            if (subgroups[x]['class'] == undefined)
              subgroups[x]['class'] = 'col-md-12 no-gutter';
            var subSettings = subgroups[x]['terminalSettings'];
            // console.log('*********');
            // console.log(subSettings);

            for (var y = 0; y < subSettings.length; y++) {
              var subSetting = subSettings[y];

              settingKeysArray.push(subSetting['code']);

              if (types.includes(subSetting['type'])) {
                if (subSetting['code'] != '' || subSetting['code'] != null) {
                  var label = subSetting['label'];
                  if (label == undefined)
                    subSetting['label'] = subSetting['code'];
                  if (subSetting['class'] == undefined)
                    subSetting['class'] = 'col-md-12 no-gutter';

                  if (this.$attrs.formSettings != undefined) {
                    if (this.$attrs.formSettings['name'] == undefined) {
                      if (subSetting['defaultValue'] != undefined) {
                        if (subSetting['type'] == 'boolean') {
                          this.$set(
                              this.settingsModel,
                              subSetting['code'],
                              Boolean(subSetting['defaultValue'])
                          );
                        } else {
                          this.$set(
                              this.settingsModel,
                              subSetting['code'],
                              subSetting['defaultValue']
                          );
                        }
                      } else {
                        this.$set(this.settingsModel, subSetting['code'], null);
                      }
                    }
                  } else {
                    if (subSetting['defaultValue'] != undefined) {
                      this.$set(
                          this.settingsModel,
                          subSetting['code'],
                          subSetting['defaultValue']
                      );
                    } else {
                      this.$set(this.settingsModel, subSetting['code'], null);
                    }
                  }

                  // Other Validations
                  var response = null;
                  switch (subSetting['type']) {
                    case 'select':
                      if (subSetting['options'] == undefined) {
                        if (subSetting['endpoint'] == undefined) {
                          subSettings.splice(i, 1);
                        } else {
                          if (
                              subSetting['endpoint'].includes('http') ||
                              subSetting['endpoint'].includes('https')
                          ) {
                            response = await HTTPRequest.getRequestThirdParty(
                                subSetting['endpoint'],
                                null
                            );
                            subSetting['options'] = response.data;
                          } else {
                            console.log('NOPEEE');
                            response = await HTTPRequest.getRequest(
                                this.endPoint + subSetting['endpoint'],
                                null
                            );
                            subSetting['options'] = response;
                          }
                          if (subSetting['options'].length > 10) {
                            subSetting['sub_type'] = subSetting['type'];
                            subSetting['type'] = 'searchable_select';
                          }
                        }
                      }
                      break;
                    case 'multiple_select':
                      if (subSetting['options'] == undefined) {
                        if (subSetting['endpoint'] == undefined) {
                          subSettings.splice(i, 1);
                        } else {
                          if (
                              subSetting['endpoint'].includes('http') ||
                              subSetting['endpoint'].includes('https')
                          ) {
                            response = await HTTPRequest.getRequestThirdParty(
                                subSetting['endpoint'],
                                null
                            );
                            subSetting['options'] = response.data;
                          } else {
                            response = await HTTPRequest.getRequest(
                                this.endPoint + subSetting['endpoint'],
                                null
                            );
                            subSetting['options'] = response;
                          }

                          //   var response = await HTTPRequest.getRequest(this.endPoint + subSetting["endpoint"], null)
                          //   subSetting["options"] = response
                          //   console.log(">>>>>>>>>>>")
                          //   console.log(subSetting["options"])

                          if (
                              subSetting['options'].length > 100 ||
                              subSetting['options'].length == 0
                          ) {
                            subSetting['options'] = [];
                            subSetting['sub_type'] = subSetting['type'];
                            subSetting['type'] = 'searchable_select';
                          }
                        }
                      }
                      break;
                    default:
                      // code block
                  }
                }
              } else {
                // console.log("Skipping -- invalid value")
                subSettings.splice(i, 1);
              }
            }
            // console.log(subSettings)
            subSettingArray.push(subSettings);
            // this.subGroupSettingsArray = subSettingArray
            this.subGroupSettingsArray = [].concat.apply([], subSettingArray);
            // console.log(this.subGroupSettingsArray)
            // groups[j]["subTerminalSettings"] = subSettings
          }
        }

        this.settingsGroupHash[groups[j]['name']] = settingKeysArray;

        // console.log("ANDITO NA")
        var settings = groups[j]['terminalSettings'];

        if (settings != undefined) {
          for (var i = 0; i < settings.length; i++) {
            var setting = settings[i];
            if (types.includes(setting['type'])) {
              if (setting['code'] != '' || setting['code'] != null) {
                var label = setting['label'];
                if (label == undefined) setting['label'] = setting['code'];
                if (setting['class'] == undefined)
                  setting['class'] = 'col-md-12 no-gutter';
                if (this.$attrs.formSettings != undefined) {
                  if (this.$attrs.formSettings['name'] == undefined) {
                    if (setting['defaultValue'] != undefined) {
                      if (setting['type'] == 'boolean') {
                        if (setting['defaultValue'] == 'true') {
                          this.$set(this.settingsModel, setting['code'], true);
                        } else {
                          this.$set(this.settingsModel, setting['code'], false);
                        }
                      } else {
                        this.$set(
                            this.settingsModel,
                            setting['code'],
                            setting['defaultValue']
                        );
                      }
                    } else {
                      this.$set(this.settingsModel, setting['code'], null);
                    }
                  }
                } else {
                  if (setting['defaultValue'] != undefined) {
                    if (setting['type'] == 'boolean') {
                      if (setting['defaultValue'] == 'true') {
                        this.$set(this.settingsModel, setting['code'], true);
                      } else {
                        this.$set(this.settingsModel, setting['code'], false);
                      }
                    } else {
                      this.$set(
                          this.settingsModel,
                          setting['code'],
                          setting['defaultValue']
                      );
                    }
                  } else {
                    this.$set(this.settingsModel, setting['code'], null);
                  }
                }

                // if(this.$attrs.formSettings["name"] == undefined){
                //     if(setting["defaultValue"] != undefined) this.settingsModel[setting["code"]] = setting["defaultValue"]
                // }
                // Other Validations
                if (setting['type'] == 'number') {
                  this.numbers.push(setting['code']);
                }
                switch (setting['type']) {
                  case 'select':
                    if (
                        setting['options'] == undefined ||
                        setting['options'].length == 0
                    ) {
                      if (setting['endpoint'] == undefined) {
                        settings.splice(i, 1);
                      } else {
                        if (
                            setting['endpoint'].includes('http') ||
                            setting['endpoint'].includes('https')
                        ) {
                          // console.log("THIRD PARTYYY")
                          var response = await HTTPRequest.getRequestThirdParty(
                              setting['endpoint'],
                              null
                          );
                          setting['options'] = response.data;
                        } else {
                          //   console.log("NOPEEE")
                          var response = await HTTPRequest.getRequest(
                              this.endPoint + setting['endpoint'],
                              null
                          );
                          setting['options'] = response;
                        }

                        //   var response = await HTTPRequest.getRequest(this.endPoint + setting["endpoint"], null)
                        //   setting["options"] = response
                        //   console.log("*****************")
                        //   console.log(setting["options"])
                        if (
                            setting['options'].length > 100 ||
                            setting['options'].length == 0
                        ) {
                          setting['options'] = [];
                          setting['sub_type'] = setting['type'];
                          setting['type'] = 'searchable_select';
                        }
                      }
                    }
                    break;
                  case 'cascader':
                    if (setting['endpoint'] == undefined) {
                      settings.splice(i, 1);
                    } else {
                      if (setting['code'] == 'categories') {
                        setting['options'] = this.categoriesOptions;
                      } else {
                        response = await HTTPRequest.getRequest(
                            this.endPoint + setting['endpoint'],
                            null
                        );
                        setting['options'] = response;
                      }
                    }
                    break;
                  case 'multiple_select':
                    if (setting['options'] == undefined) {
                      if (setting['endpoint'] == undefined) {
                        settings.splice(i, 1);
                      } else {
                        if (
                            setting['endpoint'].includes('http') ||
                            setting['endpoint'].includes('https')
                        ) {
                          response = await HTTPRequest.getRequestThirdParty(
                              setting['endpoint'],
                              null
                          );
                          setting['options'] = response.data;
                        } else {
                          console.log('NOPEEE');
                          response = await HTTPRequest.getRequest(
                              this.endPoint + setting['endpoint'],
                              null
                          );
                          setting['options'] = response;
                        }

                        //   var response = await HTTPRequest.getRequest(this.endPoint + setting["endpoint"], null)
                        //   setting["options"] = response
                        //   console.log("*****************")
                        //   console.log(setting["options"])
                        if (
                            setting['options'].length > 100 ||
                            setting['options'].length == 0
                        ) {
                          setting['options'] = [];
                          setting['sub_type'] = setting['type'];
                          setting['type'] = 'searchable_select';
                        }
                      }
                    }
                    break;
                  default:
                    // code block
                }
              }
            } else {
              // console.log("Skipping -- invalid value")
              settings.splice(i, 1);
            }
          }
          groups[j]['terminalSettings'] = settings;
        }
      }
      // create a function to combine the currentSettings Model and the this.props.formSettings
      let curSettingsModel = this.settingsModel;
      for (const key in curSettingsModel) {
        if (curSettingsModel[key] === undefined) {
          delete curSettingsModel[key];
        }
      }

      // !!!! START --- Change this part for AXIOS - if for editing or with existing value for editing
      // here changes the settingsModel!!!!!!!!!!!!!!
      if (
          this.$attrs.formSettings != null ||
          this.$attrs.formSettings != 'null'
      )
          // this.settingsModel = Object.assign(
          //   curSettingsModel,
          //   this.$attrs.formSettings
          // );
        this.$set(
            this,
            'settingsModel',
            Object.assign(curSettingsModel, this.$attrs.formSettings)
        );

      // !!!! END
      // JC: this condition|function seems not being used
      if (this.$attrs.parameters != null) {
        var settingsModelCopy = this.settingsModel; // this line seems wrong
        var mergedHash = Object.assign(
            curSettingsModel,
            settingsModelCopy,
            JSON.parse(this.$attrs.parameters)
        );
        console.log('mergedHash ', mergedHash);
        // this.settingsModel = mergedHash;
        this.$set(this, 'settingsModel', mergedHash);
        // console.log(this.settingsModel)
      }

      this.spinning = false;
      this.settingsArray = groups;
      // console.log(this.settingsArray)
      // set the settingsModel to formData.meta from memberProfileForm
      var errors = this.validateForm();
      var returnSettings = this.settingsModel;
      this.$emit(
          'valueUpdated',
          returnSettings,
          this.settingsGroupHash,
          errors
      );
    }, // end of process json

    onChange(checked) {
      // console.log(`a-switch to ${checked}`);
    },
    changeValue(value) {
      console.log('+++++++++++');
      console.log(value);
      var mergedValues = Object.assign({}, this.settingsModel, value);
      // this.settingsModel = mergedValues;
      this.$set(this, 'settingsModel', mergedValues);
    },
    handleDatePickerChange(date, datestring, code) {
      console.log('---date change---');
      console.log(datestring);
      console.log(code);
      // this.settingsModel[code] = datestring;
      this.$set(this.settingsModel, code, datestring);
    },

    // !!! END - DYNAMIC FORMS
    computeFormula(formula, field, valueCondition, labelName) {
      let t = formula;
      let computable = '';
      let start = 0,
          end = 0,
          operation;
      while (end != -1) {
        end = t.search(/[*/+\-]/);
        if (t[end] != undefined) {
          let code = t.substring(start, end);
          if (code.startsWith('(')) {
            let c = 0;
            let counter = 0;
            while (counter < code.length) {
              if (code[counter] == '(') {
                c++;
              } else {
                counter = code.length;
              }
              counter++;
            }
            code = '('.repeat(c) + this.settingsModel[code.substring(c, end)];
          } else if (code.endsWith(')')) {
            let c = 0;
            let counter = code.length - 1;
            while (counter > 0) {
              if (code[counter] == ')') {
                c++;
              } else {
                counter = 0;
              }
              counter--;
            }
            code =
                this.settingsModel[code.substring(0, end - c)] + ')'.repeat(c);
          } else {
            code = this.settingsModel[code];
          }
          computable = `${computable}${code}${t[end]}`;
        } else {
          let c = 0;
          let counter = t.length - 1;
          while (counter > 0) {
            if (t[counter] == ')') {
              c++;
            } else {
              counter = 0;
            }
            counter--;
          }
          t = this.settingsModel[t.substring(0, t.length - c)] + ')'.repeat(c);
          computable = `${computable}${t}`;
        }
        t = t.split('');
        let spliceEnd = end + 1;
        if (end === -1) spliceEnd = end;
        t.splice(start, spliceEnd);
        t = t.join('');
      }
      try {
        // Use eval to evaluate the sanitized formula
        const result = eval(computable);
        // Check if the result is a valid number
        if (!isNaN(result)) {
          // this.settingsModel[field] = result;
          let validValue = true;
          let errorMessage = 'Invalid computed value result.';
          if (valueCondition != null) {
            switch (valueCondition.condition) {
              case '>': {
                validValue =
                    parseInt(result) > parseInt(valueCondition.value.toString());
                if (validValue == false) {
                  errorMessage = `${labelName} value should be greater than ${valueCondition.value}.`;
                } else {
                  if (this.errors.includes(errorMessage)) {
                    let errorIndex = this.errors.indexOf(errorMessage);
                    if (errorIndex !== -1) {
                      this.errors.splice(errorIndex, 1);
                    }
                  }
                }
                break;
              }
              case '<': {
                validValue =
                    parseInt(result) < parseInt(valueCondition.value.toString());
                console.log('validValue ', validValue);
                if (validValue == false) {
                  errorMessage = `${labelName} value should be less than ${valueCondition.value}.`;
                } else {
                  if (this.errors.includes(errorMessage)) {
                    let errorIndex = this.errors.indexOf(errorMessage);
                    if (errorIndex !== -1) {
                      this.errors.splice(errorIndex, 1);
                    }
                  }
                }
                break;
              }
              case '>=': {
                validValue =
                    parseInt(result) >= parseInt(valueCondition.value.toString());
                console.log('validValue ', validValue);
                if (validValue == false) {
                  errorMessage = `${labelName} value should be greater than or equal to ${valueCondition.value}.`;
                } else {
                  if (this.errors.includes(errorMessage)) {
                    let errorIndex = this.errors.indexOf(errorMessage);
                    if (errorIndex !== -1) {
                      this.errors.splice(errorIndex, 1);
                    }
                  }
                }
                break;
              }
              case '<=': {
                validValue =
                    parseInt(result) <= parseInt(valueCondition.value.toString());
                console.log('validValue ', validValue);
                if (validValue == false) {
                  errorMessage = `${labelName} value should be less than or equal to ${valueCondition.value}.`;
                } else {
                  if (this.errors.includes(errorMessage)) {
                    let errorIndex = this.errors.indexOf(errorMessage);
                    if (errorIndex !== -1) {
                      this.errors.splice(errorIndex, 1);
                    }
                  }
                }
                break;
              }
              case '=': {
                validValue =
                    parseInt(result) == parseInt(valueCondition.value.toString());
                console.log('validValue ', validValue);
                if (validValue == false) {
                  errorMessage = `${labelName} value should be equal to ${valueCondition.value}.`;
                } else {
                  if (this.errors.includes(errorMessage)) {
                    let errorIndex = this.errors.indexOf(errorMessage);
                    if (errorIndex !== -1) {
                      this.errors.splice(errorIndex, 1);
                    }
                  }
                }
                break;
              }
              case 'NOT NULL': {
                validValue = result != null || result != undefined;
                console.log('validValue ', validValue);
                if (validValue == false) {
                  errorMessage = `${labelName} value should be not null.`;
                } else {
                  if (this.errors.includes(errorMessage)) {
                    let errorIndex = this.errors.indexOf(errorMessage);
                    if (errorIndex !== -1) {
                      this.errors.splice(errorIndex, 1);
                    }
                  }
                }
                break;
              }
              case 'IS NULL': {
                validValue = result == null || result == undefined;
                console.log('validValue ', validValue);
                if (validValue == false) {
                  errorMessage = `${labelName} value should be null.`;
                } else {
                  if (this.errors.includes(errorMessage)) {
                    let errorIndex = this.errors.indexOf(errorMessage);
                    if (errorIndex !== -1) {
                      this.errors.splice(errorIndex, 1);
                    }
                  }
                }
                break;
              }
              case 'IN': {
                // convert value to string
                const value = valueCondition.value.toString();
                // change value to array
                // split by ","
                // trim space
                let arr = value.split(',').map(function (value) {
                  return value.trim();
                });
                const setting = result.toString();
                validValue = arr.includes(setting);
                if (validValue == false) {
                  errorMessage = `${labelName} value should be in ${valueCondition.value}.`;
                } else {
                  if (this.errors.includes(errorMessage)) {
                    let errorIndex = this.errors.indexOf(errorMessage);
                    if (errorIndex !== -1) {
                      this.errors.splice(errorIndex, 1);
                    }
                  }
                }
                break;
              }
              default:
                validValue = true;
            }
          }

          this.$set(this.settingsModel, field, result);

          if (validValue == false) {
            throw new Error(errorMessage);
          }
          if (this.errors.includes(errorMessage)) {
            let errorIndex = this.errors.indexOf(errorMessage);
            if (errorIndex !== -1) {
              this.errors.splice(errorIndex, 1);
            }
          }
          return result;
        } else {
          throw new Error('Invalid formula or division by zero.');
        }
      } catch (error) {
        if (!this.errors.includes(error.message)) {
          this.errors.push(error.message);
        }
        return `Error: ${error.message}`;
      }
    },
    setCheckbox(val) {
      // this.settingsModel[val.model] = val.value;
      this.$set(this.settingsModel, val.model, val.value);
      var errors = this.validateForm();
      var returnSettings = this.settingsModel;
      this.$emit(
          'valueUpdated',
          returnSettings,
          this.settingsGroupHash,
          errors
      );
    }
  }
};
</script>

<style>
.indent {
  padding-left: 45px;
}

.form-control {
  height: 35px;
}

.ant-calendar-picker {
  width: 100%;
}

.dynamic-form > .ant-collapse {
  background-color: #f8f9fe;
  border: 0px;
}

.dynamic-form > .ant-collapse > .ant-collapse-item {
  border: 0px;
}

/* .dynamic-form > .ant-collapse > .ant-collapse-item-active > .ant-collapse-header{
        background-color: #F8F9FE;
        color:  black;
    } */
/*
    .dynamic-form > .ant-collapse > .ant-collapse-item-active > .ant-collapse-content-active{
        background-color: #F8F9FE;
    } */

.dynamic-form > .ant-collapse .ant-collapse-item .ant-collapse-header .anticon {
  left: initial;
  right: 16px;
}
</style>
