<template>
    <div>
        <!-- :disabled="checkDisabled(setting) == true" -->
        <base-input :required="subSetting['required']" :label="subSetting['label']" :style="subSetting.style">
            <h3 v-if="subSetting['header']"> <br> {{ subSetting['header'] }}</h3>
            <small v-if="subSetting['description']"> <br> {{ subSetting['description'] }}</small>
            <div v-if="subSetting.sub_type == 'multiple_select'">
                <a-select  
                            mode="multiple" style="width: 100%" placeholder="Please select" 
                            :filterOption="false"
                            :notFoundContent="fetching ? undefined : null" 
                            :value="valueToReturn"
                            :disabled="disabled"
                            @search="handleSearch"  
                            @change="handleChange">
                    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                    <a-select-option v-for="option in subSetting['options']" :key="option.id" :value="option.id">{{option.name}}</a-select-option>
                </a-select>
            </div>
            <div v-else>
                <a-select   showSearch 
                            style="width: 100%" placeholder="Please select" 
                            :filterOption="false"
                            :notFoundContent="fetching ? undefined : null" 
                            :value="valueToReturn"
                            :disabled="disabled"
                            @search="handleSearch"  
                            @change="handleChange">
                    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                    <a-select-option v-for="option in subSetting['options']" :key="option.id" :value="option.id">{{option.name}}</a-select-option>
                </a-select>
            </div>

        </base-input>
    </div>
</template>

<script>
    import HTTPRequest from '../controllers/httpRequest.js';
    import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState([ 'endPoint']),
    },
    mounted: function(){ 
        this.subSetting = this.$attrs.subSetting
        this.disabled = this.$attrs.disabled
    },
    data () {
        return {
            disabled: null,
            subSetting: {},
            valueToReturn: [],
            fetching: false,
            searchableSelectValue: null
        }
    },
    methods: {
        async handleSearch(value){
            this.fetching = true;
            this.subSetting.options = await HTTPRequest.getRequest(this.endPoint + this.subSetting.endpoint + "?name=" + value , null, null)   
            this.fetching = false;
        },
        handleChange(value){
            this.valueToReturn = value
            this.$emit('updateValues', this.subSetting.code, this.valueToReturn)
            this.data = []
        }
    }
    
}
</script>


<style>

</style>