/* eslint-disable no-useless-escape */
var ValidateField = {
  required(field, data, customMsg) {
    var msg = field + ' is required';
    if (customMsg) msg = customMsg;
    if (!data || data.length === 0) return msg;
  },
  requiredButAllowZero(field, data, customMsg) {
    var msg = field + ' is required';
    if (customMsg) msg = customMsg;
    if (data == undefined || data == null || data == '') return msg;
  },
  //Verify if two field input matches
  matchData(field, data1, data2) {
    var msg = field + ' should match input';
    if (data1 != data2) return msg;
  },
  isNull(field, data) {
    var msg = field + ' is required';
    if (data === null) return msg;
  },
  isEmpty(field, data) {
    var msg = 'Atleast one ' + field + ' is required';
    if (data.length == 0) return msg;
  },
  alphanumeric(field, data) {
    var re = /^[a-zA-Z0-9ñÑ]*$/;
    var msg = field + ' is not alphanumeric';
    if (data) {
      if (!re.test(data)) return msg;
    }
  },
  alphanumericWithSpecialChars(field, data) {
    var re = /^[ A-Za-zñÑ0-9_@(),:%.&/#&+-]*$/;
    var msg =
      field +
      ' field allows alphanumeric, space and _@(),:%.&/#&+- characters only';
    if (data) {
      if (!re.test(data)) return msg;
    }
  },
  alphanumericWithSpace(field, data) {
    var re = /^[a-zA-Z0-9ñÑ ]+$/;
    var msg = field + ' field does not allow special characters';
    if (data) {
      if (!re.test(data)) return msg;
    }
  },
  alphanumericWithSeparator(field, data) {
    var re = /^[a-zA-Z0-9-ñÑ_]+$/;
    var msg = field + ' field only allows dash, underscore, and alphanumeric';
    if (data) {
      if (!re.test(data)) return msg;
    }
  },
  alphabetWithSpace(field, data) {
    var re = /^[a-zA-ZñÑ ]+$/;
    var msg = field + ' field does not allow numbers and special characters';
    if (data) {
      if (!re.test(data)) return msg;
    }
  },
  //Set min and max character on the field
  charMinAndMax(field, data, min, max) {
    var re = new RegExp('^.{' + min + ',' + max + '}$');
    var msg =
      min == 0
        ? field + ' field should not exceed ' + max + ' characters'
        : field +
        ' field should have a minimum of ' +
        min +
        ' and maximum of ' +
        max +
        ' characters';
    if (data) {
      if (!re.test(data)) return msg;
    }
  },
  phoneNumberFormat(field, data) {
    var re = /^[+]{0,1}[0-9]{0,4}-{0,1}[0-9]{0,4}-{0,1}[0-9]{0,11}$/;
    var msg = field + ' format incorrect: (country code/area code + number)';
    if (data) {
      if (!re.test(data)) return msg;
    }
  },
  emailFormat(field, data) {
    // var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var msg = field + ' format incorrect';
    if (data != null) {
      if (!re.test(data)) return msg;
    }
  },
  decimalFormat(field, data) {
    var re = /^\d*\.?\d*$/;
    var msg = field + ' must be numeric';
    if (data) {
      if (!re.test(data)) return msg;
    }
  },
  wholeNumberFormat(field, data) {
    var re = /^\d*$/;
    var msg = field + ' must be numeric';
    if (data) {
      if (!re.test(data)) return msg;
    }
  },
  requiredOne(field, data) {
    if (!data.length) {
      var msg = field + ' is required atleast one';
      return msg;
    }
  },
  //Check if data is inside the array
  arrayContains(field, array, data) {
    if (!array.includes(data)) {
      var msg = field + ' is not valid';
      return msg;
    }
  },
  percentFormat(field, data) {
    var re = /^\d*\.?\d*%?$/;
    var msg = field + ' must be numeric or percentage';
    if (data) {
      if (!re.test(data)) return msg;
    }
  },
  //Two fields must not be the same
  identicalField(field1, field2, data1, data2) {
    var msg = field1 + ' and ' + field2 + ' must not be the same';
    if (data1 == data2) {
      return msg;
    }
  },
  greaterThanZero(field, data, customMsg) {
    if (data <= 0) {
      var msg = field + ' must be greater than zero';
      if (customMsg) msg = customMsg;
      return msg;
    }
  },
  positiveNumber(field, data, customMsg) {
    if (data < 0) {
      var msg = field + ' must not be a negative number';
      if (customMsg) msg = customMsg;
      return msg;
    }
  },
  lessThanZero(field, data, customMsg) {
    var msg = field + ' must be greater than zero';
    if (customMsg) msg = customMsg;
    if (data === '') {
      return msg;
    } else if (data < 0) {
      return msg;
    }
  },
  //Validate data if True or False
  validateBoolean(field, data, customMsg) {
    var msg = field + ' is invalid';
    if (customMsg) msg = customMsg;
    if (!data) return msg;
  },
  isGreaterThan(field, low, high, customMsg) {
    var msg = field + ' should be greater than its previous value';
    if (customMsg) msg = customMsg;
    if (high <= low) {
      return msg;
    }
  }
};

export default ValidateField;
